import { useRef, useEffect } from 'react'
import {
  reject,
  find,
  times,
  map,
  uniq,
  sortBy,
  reverse,
  memoize,
  filter
} from 'lodash'
// import { TYPICAL_HOLD_SIZE } from './constants'
import cuid from 'cuid'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

export function boardSettingsToTNutLocations(dimensions) {
  const tnuts = []

  const columnSpacing = dimensions.width / (dimensions.numColumns + 1)
  const rowSpacing = dimensions.height / (dimensions.numRows + 1)

  times(dimensions.numColumns, (col) => {
    times(dimensions.numRows, (row) => {
      tnuts.push({
        x: (col + 1) * columnSpacing,
        y: (row + 1) * rowSpacing,
        holdId: '',
        orientation: 0
      })
    })
  })

  return tnuts
}

export function boardUnitsToPx(n, units) {
  switch (units) {
    case 'mm':
    return n
    case 'cm':
    return n / .1
    case 'in':
    return n / 0.039
    default:
    throw new Error(`Unit ${units} not supported`)
  }
}

export function pxToBoardUnits(n, units) {
  switch (units) {
    case 'mm':
    return n
    case 'cm':
    return n * .1
    case 'in':
    return n * 0.039
    default:
    throw new Error(`Unit ${units} not supported`)
  }
}

export function generateId() {
  return cuid()
}

export function useAutofocusInput() {
  const input = useRef()
  useEffect(() => { input.current && input.current.focus() }, [])
  return input
}


export function sortAlphabetically(arr, iteratee) {
  let collator = new Intl.Collator(
    undefined, {
      numeric: true,
      sensitivity: 'base'
    }
  )

  return arr.sort((a, b) => {
    return collator.compare(
      typeof iteratee === 'string' ? a[iteratee] : iteratee(a),
      typeof iteratee === 'string' ? b[iteratee] : iteratee(b)
    )
  })
}

export const getBoardGrid = memoize((tnuts) => {
  const tnutsExcludingScrewOns = reject(tnuts, (tnut) => tnut.screwOn)
  const columns = sortBy(uniq(map(tnutsExcludingScrewOns, (tnut) => tnut.x)))
  const rows = reverse(sortBy(uniq(map(tnutsExcludingScrewOns, (tnut) => tnut.y))))

  return {
    columns: map(columns, (x, i) => [x, (i + 10).toString(36).toUpperCase()]),
    rows: map(rows, (y, i) => [y, (i + 1).toString()])
  }
})

export function getTNutLabel(board, tnut) {
  const { columns, rows } = getBoardGrid(board.data.tnuts)

  const matchedColumn = find(columns, (c) => c[0] === tnut.x)
  const matchedRow = find(rows, (r) => r[0] === tnut.y)

  if (matchedColumn && matchedRow) {
    return matchedColumn[1] + matchedRow[1]
  }
}

export function displayGrade(grade) {
  if (grade === 'unknown') {
    return '?'
  } else {
    return 'V' + grade
  }
}

export function displayUser(user) {
  if (user && user.username) {
    return user.username
  } else {
    return 'unknown'
  }
}

export function formatTimestamp(ts) {
  return dayjs(ts).format('L')
}

export function displayAverageRating(decimal) {
  return (+decimal.toFixed(1)).toString()
}

export function enforceFloat(val, { onlyPositive, allowZero } = {}) {
  val = parseFloat(val)
  if (isNaN(val)) val = 1;
  if (onlyPositive) {
    if (allowZero && val < 0) val = 1;
    if (!allowZero && val <= 0) val = 1;
  }
  return val
}

export function enforceInt(val, { onlyPositive, allowZero } = {}) {
  val = parseInt(val, 10)
  if (isNaN(val)) val = 1;
  if (onlyPositive) {
    if (allowZero && val < 0) val = 1;
    if (!allowZero && val <= 0) val = 1;
  }
  return val
}

export function enforceMax(val, max) {
  if (val > max) {
    return max
  } else {
    return val
  }
}

export function confirmAction() {
  if (window.PUPPETEER) return true;
  return window.confirm('Are you sure?')
}

export function sortProblems(problems, sortMethod) {
  switch(sortMethod) {
    case 'alpha':
    return sortAlphabetically(problems, 'name')

    case 'newest':
    return sortBy(problems, (p) => p.createdAt || 0).reverse()

    case 'oldest':
    return sortBy(problems, (p) => p.createdAt || Date.now())

    case 'best':
    return sortBy(problems, (p) => (p.ratings && p.ratings.average) ? p.ratings.average : 0).reverse()

    case 'easiest':
    return sortBy(problems, (p) => p.grade === 'unknown' ? 99 : parseInt(p.grade, 10))

    case 'hardest':
    return sortBy(problems, (p) => p.grade === 'unknown' ? -1 : parseInt(p.grade, 10)).reverse()

    default:
    throw new Error("Don't know how to sort by " + sortMethod)
  }
}

export function filterProblems(problems, filters) {
  problems = problems.slice()

  if (filters.search) {
    problems = filter(problems, (p) => p.name.toLowerCase().includes(filters.search.toLowerCase()))
  }

  if (filters.minGrade && filters.minGrade !== 0) {
    problems = filter(problems, (p) => p.grade !== 'unknown' && p.grade >= filters.minGrade)
  }

  if (filters.maxGrade && filters.maxGrade !== 14) {
    problems = filter(problems, (p) => p.grade !== 'unknown' && p.grade <= filters.maxGrade)
  }

  if (filters.setter) {
    problems = filter(problems, (p) => p.createdByUser && p.createdByUser.username === filters.setter)
  }

  if (filters.tnutId) {
    problems = filter(problems, (p) => p.holdsMap[filters.tnutId])
  }

  return problems;
}

export function sortEditingTnutFirst(tnuts, editingTnutId) {
  if (!editingTnutId) return tnuts;

  return sortBy(tnuts, (tnut) => {
    return tnut.id === editingTnutId ? 1 : 0
  })
}

export function isValidHoldsMap(holdsMap) {
  return countHoldsMapType(holdsMap, 'start') > 0 &&
         countHoldsMapType(holdsMap, 'finish') > 0
}

export function countHoldsMapType(holdsMap, type) {
  return filter(holdsMap, (v) => v === type).length
}
