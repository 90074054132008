import { produce } from 'immer'
import dbFns from '../dbFns'
import { filter } from 'lodash'

export const initialState = {
  isFetching: false,
  lastUpdated: null,
  error: null,
  data: null
}

// Actions:

export function fetchBoardList() {
  return function (dispatch, getState) {
    if (getState().boardList.isFetching) return;

    dispatch({type: 'boardList/loadBegin'})

    dbFns.getPublicBoards().then((boards) => {
      dispatch({
        type: 'boardList/loadSuccess',
        meta: {
          ts: Date.now()
        },
        payload: filter(boards, (v) => v.name !== '')
      })
    }).catch((err) => {
      console.error(err)

      dispatch({
        type: 'boardList/loadFailure',
        payload: err
      })
    })
  }
}

// Reducer:

export default function boardList(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'boardList/loadBegin':
      draft.error = null
      draft.isFetching = true
      return draft

      case 'boardList/loadSuccess':
      draft.isFetching = false
      draft.lastUpdated = action.meta.ts
      draft.data = action.payload
      return draft

      case 'boardList/loadFailure':
      draft.isFetching = false
      draft.error = action.payload
      return draft

      case 'boardList/reset':
      return initialState

      default:
      return draft
    }
  })
}
