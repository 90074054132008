import React, { Fragment, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { filter, map, find, values, range, findIndex } from 'lodash'
import {
  displayGrade,
  displayUser,
  formatTimestamp,
  displayAverageRating,
  confirmAction,
  sortProblems,
  filterProblems
} from '../utilities'
import { canManageProblem } from '../permissions'
import { createProblem, deleteProblem } from '../reducers/boards'
import { deleteTick } from '../reducers/ticks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'

import ProblemFilters from '../components/ProblemFilters'

export default function BoardHome({board, activeProblem}) {
  const dispatch = useDispatch()
  const sortBy = useSelector(s => s.ui.problemsList.sortBy)
  const filters = useSelector(s => s.ui.problemsList.filters)
  const allAddedProblems = filter(board.subcollections.problems.data, { added: true })
  const filteredSortedProblems = sortProblems(
    filterProblems(allAddedProblems, filters),
    sortBy
  )
  const ticks = useSelector((s) => s.ticks)
  const isSelectingHoldForFilter = useSelector(s => s.ui.problemsList.isSelectingHold)
  const [savedScrollY, setSavedScrollY] = useState()

  if (allAddedProblems.length === 0) return <NoProblemsSet board={board} />;

  return (
    <Fragment>
      <div className={activeProblem || isSelectingHoldForFilter ? 'xs-hide sm-hide md-hide' : ''}>
        <ProblemFilters board={board} allProblems={allAddedProblems} />
        <ProblemsList
          ticks={ticks}
          board={board}
          problems={filteredSortedProblems}
          activeProblem={activeProblem}
          toggleProblem={(problem) => {
            setSavedScrollY(window.scrollY)

            dispatch({
              type: 'ui/setActiveProblemId',
              payload: activeProblem && activeProblem.id === problem.id ?
                        null :
                        problem.id

            })
          }}
        />
      </div>

      {activeProblem ?
        <SmallScreenProblemInfo
          restoreScroll={() => {
            if (savedScrollY) window.scrollTo(0, savedScrollY);
          }}
          board={board}
          problem={activeProblem}
          allProblems={filteredSortedProblems}
          tick={find(values(ticks.data), { problemId: activeProblem.id })}
        /> : null
      }

      {
        isSelectingHoldForFilter ?
          <div className='h5 gray'>
            Select a hold to filter by or <span className='link' onClick={() => dispatch({type: 'problemsList/toggleIsSelectingHold'})}>cancel</span>
          </div> : null
      }
    </Fragment>
  )
}

function SmallScreenProblemInfo({board, allProblems, problem, tick, restoreScroll}) {
  const dispatch = useDispatch()
  const activeIdx = findIndex(allProblems, { id: problem.id })

  function backToList() {
    dispatch({type: 'ui/setActiveProblemId', payload: null})
    setTimeout(restoreScroll, 0)
  }

  function goToPrevious() {
    if (activeIdx === 0) return;
    dispatch({type: 'ui/setActiveProblemId', payload: allProblems[activeIdx - 1].id})
  }

  function goToNext() {
    if (activeIdx + 1 === allProblems.length) return;
    dispatch({type: 'ui/setActiveProblemId', payload: allProblems[activeIdx + 1].id})
  }

  return (
    <div className='lg-hide'>
      <div className='clearfix'>
        <div className='left'>
          <span
            className='link'
            onClick={backToList}
          >← Back to list</span>
        </div>

        <div className='right'>
          <span className={activeIdx === 0 ? 'gray' : 'link'} onClick={goToPrevious}>&larr; Previous</span>
          &nbsp;
          <span className={activeIdx + 1 === allProblems.length ? 'gray' : 'link'} onClick={goToNext}>Next &rarr;</span>
        </div>
      </div>

      <h2 className='h3 my1'>
        {problem.name} - {displayGrade(problem.grade)}
        {tick ? <Fragment>&nbsp;<FontAwesomeIcon icon={faCheck} color={'#2ECC40'} /></Fragment> : null}
      </h2>

      <ProblemDetails board={board} problem={problem} tick={tick} />
    </div>
  )
}

function NoProblemsSet({board}) {
  const dispatch = useDispatch()
  const history = useHistory()

  function newProblemClick() {
    let [newProblemId, thunk] = createProblem(board.data.id)
    dispatch(thunk)
    history.push(`/boards/${board.data.id}/problems/${newProblemId}/holds`)
  }

  return (
    <p className='h3 gray'>
      No problems have been set. <span // whyyyyyy
        className='link'
        onClick={newProblemClick}
        style={{whiteSpace: 'nowrap'}}
      >Create a new problem</span>
    </p>
  )
}

function ProblemsList({board, problems, toggleProblem, activeProblem, ticks}) {
  return (
    <div>
      <ul className={`list-reset mt0 border border--gray mb0`}>
        {map(problems, (problem) => {
          return <ProblemsListItem
            key={problem.id}
            board={board}
            problem={problem}
            isActive={activeProblem && activeProblem.id === problem.id}
            toggleProblem={toggleProblem}
            ticks={ticks}
          />
        })}
      </ul>

      <div className='h5 gray mt1'>{problems.length} {problems.length === 1 ? 'problem' : 'problems'} found.</div>
    </div>
  )
}

function ProblemsListItem({board, problem, toggleProblem, isActive, ticks}) {
  const tick = find(values(ticks.data), { problemId: problem.id })

  function ignoreClick(target) {
    return (
      target.nodeName === 'A' ||
      (target.nodeName === 'SPAN' && target.classList.contains('link'))
    )
  }

  return (
    <li
      className={`border-bottom last-child-border-bottom-none border--gray p2 ${isActive ? 'bg-light-gray' : ''}`}
      onClick={(e) => { if (!ignoreClick(e.target)) toggleProblem(problem); }}
    >
      <div className='flex'>
        <div className='flex-auto pr2'>
          {problem.name}
          {tick ? <Fragment>&nbsp;<FontAwesomeIcon icon={faCheck} color={'#2ECC40'} /></Fragment> : null}
        </div>
        <div>{displayGrade(problem.grade)}</div>
      </div>

      {isActive ? <ProblemDetails board={board} problem={problem} tick={tick} /> : null}
    </li>
  )
}

function ProblemDetails({board, problem, tick}) {
  const dispatch = useDispatch()
  const currentUser = useSelector(s => s.auth.currentUser)

  return (
    <div className='h6 dark-gray'>
      <div>
        Set by {displayUser(problem.createdByUser)}
        {problem.createdAt ? ` on ${formatTimestamp(problem.createdAt)}` : null}
      </div>

      {problem.feetType === 'open' ?
        <div className='tag'>Open feet</div> : null}
      <AverageRating tick={tick} ratings={problem.ratings} />
      <ul className='list-reset'>
        {
          tick ?
            <li className='inline mr1'>
              <span className='link' onClick={() => {dispatch(deleteTick(tick.id))}}>Untick</span>
            </li> :
            <li className='inline mr1'>
              <Link to={`/boards/${board.data.id}/problems/${problem.id}/tick`}>Tick</Link>
            </li>
        }
        {
          canManageProblem(currentUser, board, problem) ?
          (
            <Fragment>
              <li className='inline mr1'>
                <Link to={`/boards/${board.data.id}/problems/${problem.id}/holds`}>Edit problem</Link>
              </li>
              <li className='inline mr1'>
                <span
                  className='link'
                  onClick={() => confirmAction() && dispatch(deleteProblem(board.data.id, problem.id))}
                >Delete</span>
              </li>
            </Fragment>
          ) : null
        }
        {/*<li className='inline'><span className='link'>Add to list</span></li>*/}
      </ul>
    </div>
  )
}

function AverageRating({tick, ratings}) {
  let averageRating = ratings ? ratings.average : null
  let ratingsCount = ratings ? ratings.count : 0
  let ratingsCalculatedAt = ratings ? ratings.calculatedAt : 0

  // If ticked, and this tick is not included in the cloud-calculated rating...
  if (tick && (tick.createdAt > ratingsCalculatedAt)) {
    averageRating = ((ratingsCount * averageRating) + tick.rating) / (ratingsCount + 1)
    ratingsCount = ratingsCount + 1
  }


  if (!averageRating) {
    return <div>Not yet rated</div>
  }

  return (
    <div>
      {
        map(range(1, 5), (i) =>
          <FontAwesomeIcon key={i} icon={averageRating >= i ? faStar : (Math.ceil(averageRating) === i ? faStarHalfAlt : faStarEmpty)} color={'#ffcd4e'} />
        )
      }

      (Avg {displayAverageRating(averageRating)} from {ratingsCount} {ratingsCount === 1 ? 'rating' : 'ratings'})
    </div>
  )
}
