import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BasicPage from '../components/BasicPage'

export default function Root() {
  const currentUser = useSelector(s => s.auth.currentUser)

  // if user is logged in, redirect to user's current board
  // else redirect to home
  if (currentUser.uid) {
    if (currentUser.lastActiveBoardId) {
      return <Redirect to={`/boards/${currentUser.lastActiveBoardId}`} />
    } else {
      return <Redirect to='/home' />
    }
  } else {
    return (
      <BasicPage title='Welcome to BoardCat'>
        <Link className='btn btn-primary' to='/signup'>Sign up</Link>
        &nbsp;
        or
        &nbsp;
        <Link className='btn btn-primary' to='/signin'>Sign in</Link>
      </BasicPage>
    )
  }
}
