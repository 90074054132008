import { db } from './firebase'
import { each } from 'lodash'

function mapSnapshot(snapshot) {
  let obj = {}
  snapshot.forEach((doc) => obj[doc.id] = doc.data())
  return obj
}

const dbFns = {
  removeProblem: (boardId, problemId) => {
    return new Promise((resolve, reject) => {
      db.collection('boards')
        .doc(boardId)
        .collection('problems')
        .doc(problemId)
        .delete()
        .then(resolve)
        .catch((err) => reject(err))
    })
  },

  getHoldImages: (boardId, dispatch) => {
    db.collection('boards').doc(boardId).collection('holdImages').get().then((snapshot) => {
      dispatch({
        type: 'holdImages/loadSuccess',
        meta: {
          boardId: boardId
        },
        payload: mapSnapshot(snapshot)
      })

      // @todo add dispatches for begin, failure
    })
  },

  setHoldImagesBatch: (arr) => {
    return new Promise((resolve, reject) => {
      let batch = db.batch()

      each(arr, ([boardId, holdImageId, base64data]) => {
        let ref = db.collection(`boards/${boardId}/holdImages`).doc(holdImageId)
        batch.set(ref, { base64data: base64data })
      })

      batch
        .commit()
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },

  removeHoldImage: (boardId, holdImageId) => {
    return new Promise((resolve, reject) => {
      db.collection('boards')
        .doc(boardId)
        .collection('holdImages')
        .doc(holdImageId)
        .delete()
        .then(resolve)
        .catch((err) => reject(err))
    })
  },

  removeHoldImagesBatch: (boardId, holdImageIds) => {
    return new Promise((resolve, reject) => {
      let batch = db.batch()

      each(holdImageIds, (imageId) => {
        let ref = db.collection(`boards/${boardId}/holdImages`).doc(imageId)
        batch.delete(ref)
      })

      batch
        .commit()
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },

  saveHoldImage: (boardId, holdImageId, base64data) => {
    return new Promise((resolve, reject) => {
      db.collection('boards')
        .doc(boardId)
        .collection('holdImages')
        .doc(holdImageId)
        .set({ base64data: base64data })
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },

  setTick: (tick) => {
    db.collection('ticks')
      .doc(tick.id)
      .set(tick)
  },

  getUserDetails: (uid) => {
    return new Promise((resolve, reject) => {
      db.collection('users').doc(uid).get().then(doc => {
        resolve({
          username: doc.exists ? doc.data().username : '',
          lastActiveBoardId: doc.exists ? doc.data().lastActiveBoardId : null
        })
      })
    })
  },

  setUsername: (uid, username) => {
    db.collection('users').doc(uid).set({
      username: username
    }, { merge: true })
  },

  setLastActiveBoardId: (uid, boardId) => {
    db.collection('users').doc(uid).set({
      lastActiveBoardId: boardId
    }, { merge: true })
  },

  setBoard: (boardId, boardData) => {
    return new Promise((resolve, reject) => {
      db.collection('boards')
        .doc(boardId)
        .set(boardData)
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },

  setProblem: (boardId, problemId, problemData) => {
    return new Promise((resolve, reject) => {
      db.collection('boards')
        .doc(boardId)
        .collection('problems')
        .doc(problemId)
        .set(problemData)
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },

  deleteTick: (tickId) => {
    return db.collection('ticks').doc(tickId).delete()
  },

  getTicksForUser: (uid) => {
    return new Promise((resolve, reject) => {
      db.collection('ticks').where('userId', '==', uid).get().then((snapshot) => {
        resolve(mapSnapshot(snapshot))
      }).catch((err) => reject(err))
    })
  },

  loadBoardData: (boardId) => {
    return new Promise((resolve, reject) => {
      Promise.all([
        db.collection('boards').doc(boardId).get(),
        db.collection('boards').doc(boardId).collection('holdImages').get(),
        db.collection('boards').doc(boardId).collection('problems').get()
      ]).then((values, err) => {
        if (err) return reject(err);

        // Transform values before resolving:
        return resolve({
          board: values[0].exists ? values[0].data() : null,
          holdImages: mapSnapshot(values[1]),
          problems: mapSnapshot(values[2])
        })
      })
    })
  },

  getPublicBoards: () => {
    return new Promise((resolve, reject) => {
      db.collection('boards').where('public', '==', true).get().then((snapshot) => {
        resolve(mapSnapshot(snapshot))
      }).catch(err => reject(err))
    })
  }
}

export default dbFns
