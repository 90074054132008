import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Board } from '../../components/Board'
import { FlexContainer, LeftColumn, RightColumn } from '../../components/layout'
import RangeAndNumberInput from '../../components/RangeAndNumberInput'
import MultipleValuesInput from '../../components/MultipleValuesInput'
import SetupHeader from '../../components/SetupHeader'
import { filter, includes, map, uniq } from 'lodash'
import { pxToBoardUnits, confirmAction } from '../../utilities'
import { createTNut } from '../../reducers/boards'

export default function TNuts({board}) {
  const dispatch = useDispatch()
  const [editingIds, setEditingIds] = useState([])
  const history = useHistory()
  const editingTNuts = filter(board.data.tnuts, (i) => includes(editingIds, i.id))

  function nextStep() {
    board.data.ledEnabled ?
      history.push(`/boards/${board.data.id}/setup/leds`) :
      history.push(`/boards/${board.data.id}/setup/holds`)
  }

  function editAllBetween(currentlyEditing, newEditing) {
    const xVals = [
      ...map(currentlyEditing, 'x'),
      newEditing.x
    ]

    const yVals = [
      ...map(currentlyEditing, 'y'),
      newEditing.y
    ]

    const xRange = [Math.min(...xVals), Math.max(...xVals)]
    const yRange = [Math.min(...yVals), Math.max(...yVals)]

    const newEditingTNuts = filter(board.data.tnuts, (i) => {
      return (
        xRange[0] <= i.x &&
        i.x <= xRange[1] &&
        yRange[0] <= i.y &&
        i.y <= yRange[1]
      )
    })

    setEditingIds(map(newEditingTNuts, 'id'))
  }

  function newTNut() {
    let [newTNutId, thunk] = createTNut(board.data.id)
    dispatch(thunk)
    setEditingIds([newTNutId])
  }

  function closeEditForm() {
    setEditingIds([])
  }

  return (
    <div>
      <SetupHeader
        board={board}
        active='tnuts'
      />

      <div className='px2 lg-px3'>
        <p className='max-width-3'>
          If you need to add more tnuts or adjust the spacing, now is a good time to do it.
          If you have screw-on holds that aren't part of your grid,
          you'll be able to add those in a later step.
        </p>

        <FlexContainer>
          <LeftColumn>
            {editingTNuts.length === 0 ? <BlankSlate newTNut={newTNut} /> : null}

            {editingTNuts.length === 1 ?
              <EditTNutForm
                board={board}
                tnut={editingTNuts[0]}
                closeEditForm={closeEditForm}
              /> : null
            }

            {editingTNuts.length > 1 ?
              <EditMultipleTNutsForm
                board={board}
                tnuts={editingTNuts}
                closeEditForm={closeEditForm}
              /> : null
            }

            <div className='pt2'>
              <button onClick={nextStep} className='btn btn-primary'>
                Continue to next step →
              </button>
            </div>
          </LeftColumn>
          <RightColumn>
            <Board
              board={board}
              disableShowGrid={true}
              showHolds={false}
              tnutStyle={'dark'}
              editingTNutIds={editingIds}
              onTNutClick={(tnut, e) => {
                if (editingTNuts.length > 0 && e.evt.shiftKey) {
                  editAllBetween(editingTNuts, tnut)
                } else if (e.evt.metaKey) {
                  setEditingIds([
                    ...editingIds,
                    tnut.id
                  ])
                } else {
                  setEditingIds([tnut.id])
                }
              }}
            />
          </RightColumn>
        </FlexContainer>
      </div>
    </div>
  )
}

function EditTNutForm({board, tnut, closeEditForm}) {
  const dispatch = useDispatch()

  return (
    <div className='pb2'>
      <div>
        <label className='label' htmlFor='x'>Position X</label>

        <RangeAndNumberInput
          id='x'
          className='mb2'
          type='range'
          step={pxToBoardUnits(1, board.data.dimensions.units)}
          min={0}
          max={board.data.dimensions.width}
          value={tnut.x}
          onChange={(e) => {
            dispatch({
              type: 'tnuts/setProp',
              meta: {
                boardId: board.data.id,
                tnutId: tnut.id,
                prop: 'x'
              },
              payload: e.target.value
            })
          }}
          units={board.data.dimensions.units}
        />
      </div>

      <div>
        <label className='label' htmlFor='y'>Position Y</label>

        <RangeAndNumberInput
          id='y'
          className='mb2'
          type='range'
          step={pxToBoardUnits(1, board.data.dimensions.units)}
          min={0}
          max={board.data.dimensions.width}
          value={tnut.y}
          onChange={(e) => {
            dispatch({
              type: 'tnuts/setProp',
              meta: {
                boardId: board.data.id,
                tnutId: tnut.id,
                prop: 'y'
              },
              payload: e.target.value
            })
          }}
          units={board.data.dimensions.units}
        />
      </div>

      <button className='btn btn-primary' onClick={() => { closeEditForm() }}>Save</button>
      &nbsp;or&nbsp;
      <span
        className='link'
        onClick={() => {
          if (confirmAction()) {
            closeEditForm()

            dispatch({
              type: 'tnuts/remove',
              meta: {
                boardId: board.data.id,
                tnutId: tnut.id
              }
            })
          }
        }}
      >delete this t-nut</span>
    </div>
  )
}

function EditMultipleTNutsForm({board, tnuts, closeEditForm}) {
  const dispatch = useDispatch()
  const xVals = map(tnuts, 'x')
  const tnutsHaveSameXVal = uniq(xVals).length === 1
  const yVals = map(tnuts, 'y')
  const tnutsHaveSameYVal = uniq(yVals).length === 1

  return (
    <div className='pb2'>
      {tnutsHaveSameXVal ?
        <div>
          <label className='label' htmlFor='x'>Position X</label>
          <RangeAndNumberInput
            className='mb2'
            units={board.data.dimensions.units}
            step={pxToBoardUnits(1, board.data.dimensions.units)}
            min='0'
            max={board.data.dimensions.width}
            value={xVals[0]}
            onChange={(e) => {
              dispatch({
                type: 'tnuts/setPropMany',
                meta: {
                  boardId: board.data.id,
                  tnutIds: map(tnuts, 'id'),
                  prop: 'x'
                },
                payload: e.target.value
              })
            }}
          />
        </div> :
        <div>
          <label className='label' htmlFor='x'>Position X</label>

          <MultipleValuesInput
            className='mb1'
            id='x'
            onDecrease={() => {
              dispatch({
                type: 'tnuts/modifyLocationMany',
                meta: {
                  boardId: board.data.id,
                  tnutIds: map(tnuts, 'id'),
                  prop: 'x',
                },
                payload: -5
              })
            }}
            onIncrease={() => {
              dispatch({
                type: 'tnuts/modifyLocationMany',
                meta: {
                  boardId: board.data.id,
                  tnutIds: map(tnuts, 'id'),
                  prop: 'x',
                },
                payload: 5
              })
            }}
          />
        </div>
      }

      {tnutsHaveSameYVal ?
        <div>
          <label className='label' htmlFor='y'>Position Y</label>
          <RangeAndNumberInput
            className='mb2'
            units={board.data.dimensions.units}
            step={pxToBoardUnits(1, board.data.dimensions.units)}
            min='0'
            max={board.data.dimensions.width}
            value={yVals[0]}
            onChange={(e) => {
              dispatch({
                type: 'tnuts/setPropMany',
                meta: {
                  boardId: board.data.id,
                  tnutIds: map(tnuts, 'id'),
                  prop: 'y'
                },
                payload: e.target.value
              })
            }}
          />
        </div> :
        <div>
          <label className='label' htmlFor='y'>Position Y</label>

          <MultipleValuesInput
            className='mb1'
            id='y'
            onDecrease={() => {
              dispatch({
                type: 'tnuts/modifyLocationMany',
                meta: {
                  boardId: board.data.id,
                  tnutIds: map(tnuts, 'id'),
                  prop: 'y',
                },
                payload: -5
              })
            }}
            onIncrease={() => {
              dispatch({
                type: 'tnuts/modifyLocationMany',
                meta: {
                  boardId: board.data.id,
                  tnutIds: map(tnuts, 'id'),
                  prop: 'y',
                },
                payload: 5
              })
            }}
          />
        </div>
      }

      <button className='btn btn-primary' onClick={() => { closeEditForm() }}>Save</button>
      &nbsp;or&nbsp;
      <span
        className='link'
        onClick={() => {
          if (confirmAction()) {
            closeEditForm()

            dispatch({
              type: 'tnuts/removeMany',
              meta: {
                boardId: board.data.id,
                tnutIds: map(tnuts, 'id')
              }
            })
          }
        }}
      >delete these t-nuts</span>
    </div>
  )
}

function BlankSlate({newTNut}) {
  return (
    <p className='h3 gray'>
      Select a t-nut to edit it,
      or <span
            onClick={newTNut}
            className='link'
            style={{whiteSpace: 'nowrap'}}>create a new t-nut</span>
    </p>
  )
}
