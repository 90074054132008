import React from 'react'

export default function MultipleValuesInput({
  id,
  onDecrease,
  onIncrease,
  className
}) {
  return (
    <div className='flex'>
      <div className={`${className} col-8 mr1`}>
        <input
          className='input'
          type='text'
          disabled
          value='Multiple values…'
        />
      </div>

      <div className='flex-auto'>
        <button
          className='btn btn-secondary'
          onClick={onDecrease}
        >-</button>

        &nbsp;

        <button
          className='btn btn-secondary'
          onClick={onIncrease}
        >+</button>
      </div>
    </div>
  )
}
