import React, { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { isValidHoldsMap } from '../../utilities'

export default function Holds({board, problem}) {
  const dispatch = useDispatch()
  const history = useHistory()

  function nextStep() {
    dispatch({type: 'ui/setEditingProblemTnutId', payload: null})
    history.push(`/boards/${board.data.id}/problems/${problem.id}/details`)
  }

  function exitProblemCreation() {
    dispatch({
      type: 'ui/setActiveProblemId',
      payload: null
    })

    history.push(`/boards/${board.data.id}`)
  }

  useEffect(() => {
    dispatch({
      type: 'ui/setEditingProblemHolds',
      payload: true
    })

    return () => {
      dispatch({
        type: 'ui/setEditingProblemHolds',
        payload: false
      })
    }
  }, [problem, dispatch])

  return (
    <Fragment>
      <p className='h3 gray my0 lg-my2'>
        Select the holds for your problem by clicking on them.
      </p>

      <div className='pt2'>
        <button
          className='btn btn-secondary'
          onClick={exitProblemCreation}
        >
          Cancel
        </button>
        &nbsp;
        <button
          disabled={!isValidHoldsMap(problem.holdsMap)}
          onClick={nextStep}
          className='btn btn-primary'
        >
          Next step →
        </button>
      </div>
    </Fragment>
  )
}
