import { reject, includes, assign, pick, each, size, pull, indexOf } from 'lodash'
import {
  DEFAULT_BOARD_WIDTH,
  DEFAULT_BOARD_HEIGHT,
  DEFAULT_BOARD_UNITS,
  DEFAULT_NUM_ROWS,
  DEFAULT_NUM_COLUMNS
} from '../constants'

import errors from '../errors'

import {
  pxToBoardUnits,
  getTNutLabel,
  generateId,
  enforceFloat,
  enforceInt,
  enforceMax,
  countHoldsMapType
} from '../utilities'
import { produce } from 'immer'
import dbFns from '../dbFns'

// Action creators:

export function createBoard() {
  let boardId = generateId()
  let thunk = (dispatch, getState) => {
    dispatch({
      type: 'boards/create',
      meta: {
        boardId: boardId,
        user: getState().auth.currentUser,
        ts: Date.now()
      }
    })

    // In case the boardsList was loaded, clear it.
    dispatch({type: 'boardList/reset'})
  }
  return [boardId, thunk]
}

export function createTNut(boardId, data = {}) {
  let tnutId = generateId()
  let thunk = (dispatch) => {
    dispatch({
      type: 'tnuts/create',
      meta: {
        boardId: boardId,
        tnutId: tnutId
      },
      payload: data
    })
  }
  return [tnutId, thunk]
}

export function fetchBoard(id) {
  return function (dispatch, getState) {
    // Board already loaded (or already fetching)
    if (getState().boards[id]) return Promise.resolve();

    // Reset the UI, as it is not shared between boards:
    // https://github.com/ajb/boardcat/issues/67
    dispatch({type: 'ui/reset'})
    dispatch({type: 'boards/loadBegin', meta: { boardId: id }})

    return new Promise((resolve, reject) => {
      dbFns.loadBoardData(id).then(({board, holdImages, problems}) => {
        if (!board) {
          dispatch({
            type: 'boards/loadFailure',
            meta: { boardId: id },
            payload: errors.boardNotFound
          })
          reject(errors.boardNotFound)
        } else {
          dispatch({
            type: 'boards/loadSuccess',
            meta: { boardId: id, ts: Date.now() },
            payload: {
              boardData: board,
              holdImagesData: holdImages,
              problemsData: problems
            }
          })
          resolve()
        }
      }).catch((err) => {
        dispatch({type: 'boards/loadFailure', meta: { boardId: id }, payload: err})
        reject(err)
      })
    })
  }
}

export function createProblem(boardId) {
  let problemId = generateId()
  let thunk = (dispatch, getState) => {
    dispatch({
      type: 'problems/create',
      meta: {
        boardId: boardId,
        problemId: problemId,
        user: getState().auth.currentUser,
        ts: Date.now()
      }
    })
  }
  return [problemId, thunk]
}

export function deleteProblem(boardId, problemId) {
  return (dispatch) => {
    dispatch({
      type: 'ui/setActiveProblemId',
      payload: null
    })

    dispatch({
      type: 'problems/remove',
      meta: {
        boardId: boardId,
        problemId: problemId
      }
    })
  }
}

// The cycle: start -> middle -> finish -> foot
export function cycleProblemHold(board, problem, tnut){
  return (dispatch) => {
    // Exit early if the tnut doesn't have a hold
    if (!tnut.holdId) return;

    let holdType = problem.holdsMap[tnut.id]
    let newHoldType = null
    let cycle = ['start', 'middle', 'finish', 'foot']

    // Remove "start" from the cycle if there are already two start holds
    if (countHoldsMapType(problem.holdsMap, 'start') === 2) pull(cycle, 'start');

    // Remove "finish" from the cycle if there are already two finish holds
    if (countHoldsMapType(problem.holdsMap, 'finish') === 2) pull(cycle, 'finish');

    // Handle edge case:
    if (holdType === 'finish' && indexOf(cycle, 'finish') === -1) {
      newHoldType = 'foot'
    } else {
      // If the hold is not already in the problem, then select the first item in the cycle.
      // Otherwise, select the next item in the cycle.
      newHoldType = !holdType ?
                      cycle[0] :
                      cycle[indexOf(cycle, holdType) + 1]
    }


    // This tells the UI to show the hold type label
    dispatch({
      type: 'ui/setEditingProblemTnutId',
      payload: newHoldType ? tnut.id : null
    })

    if (newHoldType) {
      dispatch({
        type: 'problems/setHold',
        meta: {
          boardId: board.data.id,
          problemId: problem.id,
          tnutId: tnut.id
        },
        payload: newHoldType
      })
    } else {
      dispatch({
        type: 'problems/removeHold',
        meta: {
          boardId: board.data.id,
          problemId: problem.id,
          tnutId: tnut.id
        }
      })
    }
  }
}

// Reducer:
// @todo remove any reference to dbFns *out* of the reducer and into an
// action creator.

export default function boards(state = {}, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'boards/create':
      draft[action.meta.boardId] = {
        isFetching: false,
        lastUpdated: null,
        error: null,
        data: {
          id: action.meta.boardId,
          createdAt: action.meta.ts,
          name: '',
          setupComplete: false,
          displayOptions: {
            showGrid: true
          },
          dimensions: {
            width: DEFAULT_BOARD_WIDTH,
            height: DEFAULT_BOARD_HEIGHT,
            units: DEFAULT_BOARD_UNITS,
            numRows: DEFAULT_NUM_ROWS,
            numColumns: DEFAULT_NUM_COLUMNS
          },
          tnuts: {},
          holds: {},
          admins: [action.meta.user.uid]
        },
        subcollections: {
          holdImages: { data: {} },
          problems: { data: {} }
        }
      }
      return draft

      case 'boards/invalidate':
      // for now, we just remove the board
      delete draft[action.meta.boardId]
      return draft

      case 'boards/loadBegin':
      if (!draft[action.meta.boardId]) {
        draft[action.meta.boardId] = {
          isFetching: true,
          error: null,
          data: null,
          subcollections: {
            holdImages: { data: null },
            problems: { data: null }
          }
        }
      } else {
        draft[action.meta.boardId].isFetching = true
      }

      return draft

      case 'boards/loadFailure':
      draft[action.meta.boardId].isFetching = false
      draft[action.meta.boardId].error = action.payload
      return draft

      case 'boards/loadSuccess':
      draft[action.meta.boardId].lastUpdated = action.meta.ts
      draft[action.meta.boardId].isFetching = false
      draft[action.meta.boardId].data = action.payload.boardData

      // Set displayOptions if null
      draft[action.meta.boardId].data.displayOptions =
        draft[action.meta.boardId].data.displayOptions || {}

      // Migrate old holds arrays to holdsMap:
      each(action.payload.problemsData, (v, k) => {
        if (!v.holdsMap) {
          v.holdsMap = {};

          each(v.holds || [], (tnutId) => {
            if (includes(v.startHolds, tnutId)) {
              v.holdsMap[tnutId] = 'start';
            } else if (includes(v.finishHolds, tnutId)) {
              v.holdsMap[tnutId] = 'finish';
            } else {
              v.holdsMap[tnutId] = 'middle';
            }
          })
        }
      })

      draft[action.meta.boardId].subcollections.holdImages.data = action.payload.holdImagesData
      draft[action.meta.boardId].subcollections.problems.data = action.payload.problemsData
      return draft

      case 'boards/setProp':
      draft[action.meta.boardId].data[action.meta.prop] = action.payload
      return draft

      case 'boards/setDisplayOptionsProp':
      if (['clickTargetSizeAdjustment', 'screwOnClickTargetSizeAdjustment'].includes(action.meta.prop)) {
        action.payload = enforceInt(action.payload)
      }

      draft[action.meta.boardId].data.displayOptions[action.meta.prop] = action.payload
      return draft

      case 'boards/setDimensionsProp':
      if (['width', 'height'].includes(action.meta.prop)) {
        action.payload = enforceFloat(action.payload, { onlyPositive: true })
      }

      if (['numColumns', 'numRows'].includes(action.meta.prop)) {
        action.payload = enforceInt(action.payload, { onlyPositive: true })
        action.payload = enforceMax(action.payload, 100)
      }

      draft[action.meta.boardId].data.dimensions[action.meta.prop] = action.payload
      return draft


      case 'tnuts/setProp':
      if (['x', 'y'].includes(action.meta.prop)) {
        action.payload = enforceFloat(action.payload, { onlyPositive: true })
      }

      if (['orientation', 'ledIdx'].includes(action.meta.prop)) {
        action.payload = action.payload === '' ? '' : enforceInt(action.payload, { onlyPositive: true, allowZero: true })
      }

      draft[action.meta.boardId].data.tnuts[action.meta.tnutId][action.meta.prop] = action.payload
      return draft

      case 'tnuts/setLocation':
      draft[action.meta.boardId].data.tnuts[action.meta.tnutId].x = action.payload[0]
      draft[action.meta.boardId].data.tnuts[action.meta.tnutId].y = action.payload[1]
      return draft

      case 'tnuts/setPropMany':
      if (['x', 'y'].includes(action.meta.prop)) {
        action.payload = enforceFloat(action.payload, { onlyPositive: true })
      }

      if (['orientation'].includes(action.meta.prop)) {
        action.payload = enforceInt(action.payload, { onlyPositive: true, allowZero: true })
      }

      each(action.meta.tnutIds, (tnutId) => {
        draft[action.meta.boardId].data.tnuts[tnutId][action.meta.prop] = action.payload
      })
      return draft

      case 'tnuts/modifyLocationMany':
      each(action.meta.tnutIds, (tnutId) => {
        draft[action.meta.boardId].data.tnuts[tnutId][action.meta.prop] =
          draft[action.meta.boardId].data.tnuts[tnutId][action.meta.prop] +
          pxToBoardUnits(action.payload, draft[action.meta.boardId].data.dimensions.units)
      })
      return draft

      case 'tnuts/create':
      draft[action.meta.boardId].data.tnuts[action.meta.tnutId] = {
        id: action.meta.tnutId,
        x: action.payload.x || 1,
        y: action.payload.y || 1,
        holdId: action.payload.holdId || '',
        screwOn: action.payload.screwOn || false,
        orientation: 0
      }
      return draft

      case 'tnuts/remove':
      delete draft[action.meta.boardId].data.tnuts[action.meta.tnutId]
      return draft
      // @todo remove dependent problems?

      case 'tnuts/removeMany':
      each(action.meta.tnutIds, (tnutId) => {
        delete draft[action.meta.boardId].data.tnuts[tnutId]
      })
      return draft

      case 'boards/setInitialTNuts':
      if (size(draft[action.meta.boardId].data.tnuts) === 0) {
        each(action.payload, (tnut) => {
          let id = generateId()
          draft[action.meta.boardId].data.tnuts[id] = {
            id: id,
            ...tnut
          }
        })
      }
      return draft

      case 'holds/createFromFiles':
      each(action.payload, (hold) => {
        draft[action.meta.boardId].data.holds[hold.id] = hold

        each(
          reject(draft[action.meta.boardId].data.tnuts, (tnut) => tnut.screwOn),
          (tnut) => {
            let tNutLabel = getTNutLabel(draft[action.meta.boardId], tnut)
            if (hold.name.toUpperCase() === tNutLabel.toUpperCase() && !tnut.holdId) {
              tnut.holdId = hold.id
            }
          }
        )
      })

      return draft

      case 'holds/replaceImage':
      assign(
        draft[action.meta.boardId].data.holds[action.meta.holdId],
        pick(action.payload, 'imageId', 'height', 'width')
      )

      // update data in subcollection without a server roundtrip
      draft[action.meta.boardId].subcollections.holdImages.data[action.payload.imageId] = {
        base64data: action.payload.base64data
      }

      return draft

      case 'holds/setProp':
      // enforce number and enforce min for certain props
      if (['scale', 'boltLocationX', 'boltLocationY'].includes(action.meta.prop)) {
        action.payload = enforceFloat(action.payload, { onlyPositive: true })
      }

      draft[action.meta.boardId].data.holds[action.meta.holdId][action.meta.prop] = action.payload
      return draft

      case 'holds/setPropMany':
      // scale must be > 0
      if (['scale'].includes(action.meta.prop)) {
        action.payload = enforceFloat(action.payload, { onlyPositive: true })
      }

      each(action.meta.holdIds, (holdId) => {
        draft[action.meta.boardId].data.holds[holdId][action.meta.prop] = action.payload
      })
      return draft

      case 'holds/incrementPropMany':
      action.payload = enforceFloat(action.payload)

      each(action.meta.holdIds, (holdId) => {
        draft[action.meta.boardId].data.holds[holdId][action.meta.prop] =
          enforceFloat(draft[action.meta.boardId].data.holds[holdId][action.meta.prop]) + action.payload
      })
      return draft

      case 'holds/remove':
      let imageId = draft[action.meta.boardId].data.holds[action.meta.holdId].imageId
      delete draft[action.meta.boardId].data.holds[action.meta.holdId]

      dbFns.removeHoldImage(action.meta.boardId, imageId)

      // @todo remove references to hold in tnuts
      // what to do with problems that use this hold? let user decide?

      return draft

      case 'holds/removeMany':
      let imageIds = []
      each(action.meta.holdIds, (holdId) => {
        imageIds.push(draft[action.meta.boardId].data.holds[holdId].imageId)
        delete draft[action.meta.boardId].data.holds[holdId]
      })

      dbFns.removeHoldImagesBatch(action.meta.boardId, imageIds)

      return draft

      case 'problems/create':
      draft[action.meta.boardId].subcollections.problems.data[action.meta.problemId] = {
        id: action.meta.problemId,
        name: '',
        grade: 'unknown',
        holdsMap: {},
        feetType: 'tracking',
        createdByUser: pick(action.meta.user, 'uid', 'username'),
        createdAt: action.meta.ts
      }

      return draft

      case 'problems/remove':
      delete draft[action.meta.boardId].subcollections.problems.data[action.meta.problemId]
      dbFns.removeProblem(
        action.meta.boardId,
        action.meta.problemId
      )

      return draft

      case 'problems/setProp':
      draft[action.meta.boardId].subcollections.problems.data[action.meta.problemId][action.meta.prop] = action.payload
      return draft

      case 'problems/setHold':
      draft[action.meta.boardId].subcollections.problems.data[action.meta.problemId].holdsMap[action.meta.tnutId] = action.payload
      return draft

      case 'problems/removeHold':
      delete draft[action.meta.boardId].subcollections.problems.data[action.meta.problemId].holdsMap[action.meta.tnutId]
      return draft

      case 'holdImages/loadSuccess':
      draft[action.meta.boardId].subcollections.holdImages.data = action.payload
      return draft

      default:
      return draft
    }
  })
}
