import React, { useState, useEffect } from 'react'
import BasicPage from './BasicPage'

export default function LoadingScreen() {
  const [animatedText, setAnimatedText] = useState('')

  useEffect(() => {
    let i = 0
    let cycle = ['.', '..', '...', '....', '.....']
    let interval = setInterval(() => {
      setAnimatedText(cycle[i])
      i === (cycle.length - 1) ? i = 0 : i++
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [setAnimatedText])

  return (
    <BasicPage title={`BoardCat is loading${animatedText}`} />
  )
}
