import React from 'react'
import Select, { components } from 'react-select'
import { find } from 'lodash'

// @todo eventually display in a modal or something because:
// 1) react-select is unmaintained
// 2) it would be better to display the images in a <canvas>

export default function HoldSelect({tnutId, options, value, onChange}) {
  const selectedOption = find(options, (opt) => opt.value === value)

  return (
    <div className='pb1 HoldSelect'>
      <Select
        key={tnutId}
        isClearable={true}
        value={selectedOption}
        options={options}
        onChange={onChange}
        components={{Option}}
      />
    </div>
  )
}

const Option = (props) => {
  const {data} = props;
  return (
    <components.Option {...props}>
      <div className='flex items-center'>
        <div className='center' style={{width: '50px'}}>
          <img src={data.image} style={{maxWidth: '100%'}} alt='hold' />
        </div>
        <div style={{flex: 'auto', paddingLeft: '1rem'}}>{data.label}</div>
      </div>
    </components.Option>
  );
};
