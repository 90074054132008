import React, { useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { useAutofocusInput } from '../utilities'
import { useSelector, useDispatch } from 'react-redux'
import { createAccount } from '../reducers/auth'
import BasicPage from '../components/BasicPage'

export default function Signup() {
  const accountCreation = useSelector(s => s.auth.accountCreation)
  const [form, setForm] = useState({
    username: '',
    email: '',
    password: ''
  })
  const dispatch = useDispatch()
  const emailInput = useAutofocusInput()

  // Redirect home after account creation
  if (accountCreation.isSuccess) {
    return <Redirect to='/' />
  }

  function handleSubmit(e) {
    e.preventDefault()
    dispatch(createAccount(form))
  }

  return (
    <BasicPage title='Sign up for BoardCat'>
      <form className='left-align max-width-2 mx-auto' onSubmit={handleSubmit}>
        <label htmlFor='email' className='label'>Email</label>
        <input
          ref={emailInput}
          id='email'
          type='email'
          value={form.email}
          className='input'
          onChange={e => setForm({ ...form, email: e.target.value })}
          required
        />

        <label htmlFor='username' className='label'>Username</label>
        <input
          id='username'
          type='text'
          value={form.username}
          className='input'
          onChange={e => setForm({ ...form, username: e.target.value })}
          required
        />

        <label htmlFor='password' className='label'>Password</label>
        <input
          id='password'
          type='password'
          value={form.password}
          className='input'
          onChange={e => setForm({ ...form, password: e.target.value })}
          required
        />

        {
          accountCreation.error ?
            <p>{accountCreation.error.message}</p> : null
        }

        <Link to='/' className='btn btn-secondary'>Back</Link>

        &nbsp;

        <button
          className='btn btn-primary'
          disabled={accountCreation.isPending || (!form.username || !form.email || !form.password)}
        >{accountCreation.isPending ? 'Signing up…' : 'Sign up'}</button>
      </form>
    </BasicPage>
  )
}
