import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { find, reject, uniq, map, range } from 'lodash'
import { getTNutLabel } from '../utilities'

export default function ProblemFilters({board, allProblems}) {
  const problemsListState = useSelector(s => s.ui.problemsList)
  const dispatch = useDispatch()
  const allSetters = reject(uniq(map(allProblems, (p) => p.createdByUser && p.createdByUser.username)), (v) => !v)

  const filtersActive = (
    problemsListState.filters.search ||
    (problemsListState.filters.minGrade && problemsListState.filters.minGrade !== 0) ||
    (problemsListState.filters.maxGrade && problemsListState.filters.maxGrade !== 14) ||
    problemsListState.filters.setter ||
    problemsListState.filters.tnutId ||
    problemsListState.sortBy !== 'alpha'
  )

  const selectedTnut = find(board.data.tnuts, { id: problemsListState.filters.tnutId })
  const selectedHold = selectedTnut && selectedTnut.id && find(board.data.holds, {id: selectedTnut.holdId})
  const selectedHoldLabel = selectedTnut && (
    getTNutLabel(board, selectedTnut) ||
    selectedHold.name ||
    problemsListState.filters.tnutId
  )

  function selectHold() {
    dispatch({type: 'problemsList/toggleIsSelectingHold'})
  }

  function clearHold() {
    dispatch({
      type: 'problemsList/setFilter',
      meta: { prop: 'tnutId' },
      payload: null
    })
  }

  return (
    <div className='ProblemFilters'>
      <div className='FilterInput'>
        <input
          type='text'
          placeholder='Search problems…'
          className='input'
          value={problemsListState.filters.search || ''}
          onChange={(e) => dispatch({type: 'problemsList/setFilter', meta: { prop: 'search' }, payload: e.target.value})}
        />

        <button
          onClick={() => dispatch({type: 'problemsList/toggleShowFilters'})}
          className={`btn btn-small ${filtersActive ? 'btn-primary' : 'btn-secondary'}`}
        >
          <FontAwesomeIcon icon={faFilter} color={'#fff'} />
        </button>
      </div>

      <div className='ProblemFilters-Filters' style={problemsListState.showFilters ? {} : {display: 'none'}}>
        <div className='ProblemFilters-Filter'>
          <label className='label'>Grade</label>
          <div className='flex items-center'>
            <select
              className='select'
              value={problemsListState.filters.minGrade || 0}
              onChange={(e) => dispatch({type: 'problemsList/setFilter', meta: { prop: 'minGrade' }, payload: e.target.value})}
            >
              {
                map(range(0, 15), (num) =>
                  <option value={num} key={num}>V{num}</option>
                )
              }
            </select>
            <span className='px2'>to</span>
            <select
              className='select'
              value={problemsListState.filters.maxGrade || 14}
              onChange={(e) => dispatch({type: 'problemsList/setFilter', meta: { prop: 'maxGrade' }, payload: e.target.value})}
            >
              {
                map(range(0, 15), (num) =>
                  <option value={num} key={num}>V{num}</option>
                )
              }
            </select>
          </div>
        </div>

        <div className='ProblemFilters-Filter'>
          <label className='label'>Setter</label>
          <select
            className='select'
            value={problemsListState.filters.setter || ''}
            onChange={(e) => dispatch({type: 'problemsList/setFilter', meta: { prop: 'setter' }, payload: e.target.value})}
          >
            <option value=''>All setters</option>

            {
              map(allSetters, (setter) =>
                <option value={setter} key={setter}>{setter}</option>
              )
            }
          </select>
        </div>

        <div className='ProblemFilters-Filter'>
          <label className='label'>Hold</label>
          {
            problemsListState.filters.tnutId ?
              (
                <div>
                  {selectedHoldLabel}
                  &nbsp;
                  <span className='btn btn-small btn-secondary' onClick={clearHold}>Clear</span>
                </div>
              ) :
              (
                problemsListState.isSelectingHold ?
                  (
                    <div className='h5 gray'>
                      Click a hold on the board or <span className='btn btn-small btn-secondary' onClick={selectHold}>cancel</span>
                    </div>
                  ) :
                  <button className='btn btn-small btn-primary' onClick={selectHold}>Select a hold</button>
              )
          }
        </div>

        <div className='ProblemFilters-Filter'>
          <label className='label'>Sort by</label>
          <select
            value={problemsListState.sortBy}
            onChange={(e) => {dispatch({type: 'problemsList/setSortBy', payload: e.target.value})}}
            className='select'
          >
            <option value='alpha'>A-Z</option>
            <option value='newest'>Newest</option>
            <option value='oldest'>Oldest</option>
            <option value='best'>Best</option>
            <option value='easiest'>Easiest</option>
            <option value='hardest'>Hardest</option>
          </select>
        </div>

        <div className='ProblemFilters-Actions'>
          <button
            className='btn btn-primary'
            onClick={() => dispatch({type: 'problemsList/toggleShowFilters'})}
          >Done</button>
          &nbsp;
          <button
            className='btn btn-secondary'
            onClick={() => dispatch({type: 'problemsList/clearFilters'})}
          >Clear filters</button>
        </div>
      </div>
    </div>
  )
}
