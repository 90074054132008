import { includes } from 'lodash'
import { SUPERADMIN_UIDS } from './constants'

export function canManageBoard(user, board) {
  if (!user) return false;
  if (SUPERADMIN_UIDS.includes(user.uid)) return true;
  return includes(board.data.admins || [], user.uid)
}

export function canManageProblem(user, board, problem) {
  if (!user) return false;

  return (
    (problem.createdByUser && problem.createdByUser.uid === user.uid) ||
    canManageBoard(user, board)
  )
}
