import { includes, startsWith, debounce } from 'lodash'
import dbFns from './dbFns'

const excludedActions = [
  'boards/invalidate',
  'boards/loadBegin',
  'boards/loadSuccess',
  'boards/loadFailure',
  'problems/remove',
  'holdImages/loadSuccess'
]

const boardUpdateFns = {}
const problemUpdateFns = {}

function queueBoardUpdate(store, boardId) {
  if (!boardUpdateFns[boardId]) {
    boardUpdateFns[boardId] = debounce(() => {
      store.dispatch({ type: 'ui/incrementSavesInProgress' })
      store.dispatch({ type: 'ui/setBoardUpdateQueued', payload: false })

      dbFns.setBoard(boardId, store.getState().boards[boardId].data).finally(() => {
        store.dispatch({ type: 'ui/decrementSavesInProgress' })
      })
    }, 1000)
  }

  boardUpdateFns[boardId]()
  store.dispatch({ type: 'ui/setBoardUpdateQueued', payload: true })
}

function queueProblemUpdate(store, boardId, problemId) {
  if (!problemUpdateFns[problemId]) {
    problemUpdateFns[problemId] = debounce(() => {
      let problem = store.getState().boards[boardId].subcollections.problems.data[problemId]
      store.dispatch({ type: 'ui/setProblemUpdateQueued', payload: false })

      // Don't save unadded problems
      if (!problem.added) return;

      store.dispatch({ type: 'ui/incrementSavesInProgress' })

      dbFns.setProblem(boardId, problemId, problem).finally(() => {
        store.dispatch({ type: 'ui/decrementSavesInProgress' })
      })
    }, 1000)
  }

  problemUpdateFns[problemId]()
  store.dispatch({ type: 'ui/setProblemUpdateQueued', payload: true })
}

const firestoreController = store => next => action => {
  if (
    action.meta &&
    action.meta.boardId &&
    !includes(excludedActions, action.type) &&

    // Problems are in a subcollection, so no need to update here
    !startsWith(action.type, 'problems/')
  ) {
    queueBoardUpdate(store, action.meta.boardId)
  }

  if (
    action.meta &&
    action.meta.boardId &&
    action.meta.problemId &&
    !includes(excludedActions, action.type)
  ) {
    queueProblemUpdate(store, action.meta.boardId, action.meta.problemId)
  }

  return next(action)
}

export default firestoreController
