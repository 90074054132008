import { combineReducers } from 'redux'
import boardList from './boardList'
import boards from './boards'
import ticks from './ticks'
import ui from './ui'
import auth from './auth'
import bluetooth from './bluetooth'

export default combineReducers({
  boardList,
  boards,
  ticks,
  ui,
  auth,
  bluetooth
})
