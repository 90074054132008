import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import SetupRoutes from './SetupRoutes'
import ProblemRoutes from './ProblemRoutes'
import BoardHome from '../pages/BoardHome'
import BoardHeader from '../components/BoardHeader'
import LoadingScreen from '../components/LoadingScreen'
import BasicPage from '../components/BasicPage'
import NotFoundPage from '../components/NotFoundPage'
import { Board } from '../components/Board'
import { FlexContainer, LeftColumn, RightColumn } from '../components/layout'
import { find } from 'lodash'
import {
  fetchBoard,
  cycleProblemHold,
} from '../reducers/boards'
import { setLastActiveBoardId } from '../reducers/auth'
import { setLedsByProblem, clearLeds } from '../bluetooth'

export default function BoardRoutes() {
  const params = useParams()
  const boardIdParam = params.id
  const board = useSelector(s => s.boards[params.id])
  const currentUser = useSelector(s => s.auth.currentUser)
  const boardDataId = board && board.data && board.data.id
  const currentUserUid = currentUser && currentUser.uid
  const dispatch = useDispatch()

  // Fetch the board
  useEffect(() => {
    if (!board) dispatch(fetchBoard(boardIdParam)).catch(() => {});
  }, [board, boardIdParam, dispatch])

  // Save the lastActiveBoardId to this user
  useEffect(() => {
    if (boardDataId && currentUserUid) {
      dispatch(setLastActiveBoardId(currentUserUid, boardDataId))
    }
  }, [boardDataId, currentUserUid, dispatch])

  if (!board || board.isFetching) return <LoadingScreen />;

  if (board.error) {
    if (board.error.code === 'boards/notFound') {
      return <NotFoundPage />
    } else {
      return <BasicPage title={`Error: ${board.error}`} />
    }
  }

  return (
    <Switch>
      <Route path="/boards/:id/setup"><SetupRoutes board={board} /></Route>
      <Route path="/boards/:id"><MainBoardApp board={board} /></Route>
    </Switch>
  )
}

function MainBoardApp({board}) {
  const activeProblemId = useSelector(s => s.ui.activeProblemId)
  const editingProblemHolds = useSelector(s => s.ui.editingProblemHolds)
  const editingProblemTnutId = useSelector(s => s.ui.editingProblemTnutId)
  const activeProblem = find(board.subcollections.problems.data, { id: activeProblemId })
  const isSelectingHoldForFilter = useSelector(s => s.ui.problemsList.isSelectingHold)
  const dispatch = useDispatch()

  useEffect(() => {
    if (activeProblem) {
      setLedsByProblem(board, activeProblem)
    } else {
      clearLeds()
    }
  }, [board, activeProblem, dispatch])

  if (!board.data.setupComplete) {
    return <Redirect to={`/boards/${board.data.id}/setup`} />
  }

  function onTNutClick(tnut) {
    if (editingProblemHolds) {
      dispatch(cycleProblemHold(board, activeProblem, tnut))
    }

    if (isSelectingHoldForFilter) {
      dispatch({type: 'problemsList/toggleIsSelectingHold'})

      dispatch({
        type: 'problemsList/setFilter',
        meta: { prop: 'tnutId' },
        payload: tnut.id
      })
    }
  }

  return (
    <Fragment>
      <BoardHeader board={board} />

      <FlexContainer>
        <LeftColumn>
          <div className='px2 lg-pl3 lg-pr0'>
            <Route exact path="/boards/:id">
              <BoardHome board={board} activeProblem={activeProblem} />
            </Route>

            <Route path="/boards/:id/problems/:problemId">
              <ProblemRoutes board={board} activeProblem={activeProblem} />
            </Route>
          </div>
        </LeftColumn>
        <RightColumn>
          <div className={!activeProblem && !window.PUPPETEER && !isSelectingHoldForFilter ? 'hide-board-wrapper' : ''}>
            <Board
              board={board}
              problem={activeProblem}
              onTNutClick={onTNutClick}
              editingProblemTnutId={editingProblemHolds && editingProblemTnutId}
            />
          </div>
        </RightColumn>
      </FlexContainer>
    </Fragment>
  )
}
