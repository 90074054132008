import { produce } from 'immer'
import { connect } from '../bluetooth'

export const initialState = {
  isConnecting: false,
  isConnected: false
}

// Action creators:

export function connectBluetooth() {
  return (dispatch) => {
    connect({
      onConnecting: () => {
        dispatch({type: 'bluetooth/connecting'})
      },
      onConnected: () => {
        dispatch({type: 'bluetooth/connected'})
      },
      onErrorConnecting: () => {
        dispatch({type: 'bluetooth/errorConnecting'})
      },
      onDisconnected: () => {
        dispatch({type: 'bluetooth/disconnected'})
      }
    })
  }
}

// Reducer:

export default function bluetooth(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'bluetooth/connecting':
      draft.isConnecting = true
      return draft

      case 'bluetooth/errorConnecting':
      draft.isConnecting = false
      return draft

      case 'bluetooth/connected':
      draft.isConnecting = false
      draft.isConnected = true
      return draft

      case 'bluetooth/disconnected':
      draft.isConnected = false
      return draft

      default:
      return draft
    }
  })
}
