import { produce } from 'immer'
import firebase from '../firebase'
import { fetchBoard } from './boards'
import { fetchTicks } from './ticks'
import dbFns from '../dbFns'

export const initialState = {
  authStateReceived: false,
  currentUser: {},
  accountCreation: {
    isSuccess: false,
    isPending: false,
    error: null
  },
  signin: {
    isSuccess: false,
    isPending: false,
    error: null
  }
}

export const excludePersistence = [
  'accountCreation',
  'signin'
]

// Action creators:

export function loadUserDataAndSetCurrentUser(user) {
  return (dispatch) => {
    // Get the user's ticks
    dispatch(fetchTicks(user.uid))

    // Get additional user details from the 'users' collection in firestore
    dbFns.getUserDetails(user.uid).then((userDetails) => {
      let userPayload = {
        uid: user.uid,
        email: user.email,
        username: userDetails.username,
        lastActiveBoardId: userDetails.lastActiveBoardId
      }

      if (userPayload.lastActiveBoardId) {
        dispatch(fetchBoard(userPayload.lastActiveBoardId))
          .catch((err) => console.error('Error fetching last active board:', err))
          .finally(() => dispatch({type: 'auth/setCurrentUser', payload: userPayload}))
      } else {
        dispatch({type: 'auth/setCurrentUser', payload: userPayload})
      }
    })
  }
}

export function createAccount({username, email, password}) {
  return (dispatch) => {
    dispatch({type: 'auth/accountCreationBegin'})

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        dispatch({type: 'auth/accountCreationSuccess'})
        dispatch(setUsername(res.user.uid, username))
      }, (e) => {
        dispatch({type: 'auth/accountCreationFailure', payload: e})
      })
  }
}

export function signIn({email, password}) {
  return (dispatch) => {
    dispatch({type: 'auth/signinBegin'})

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        dispatch({type: 'auth/signinSuccess'})
      }, (e) => {
        dispatch({type: 'auth/signinFailure', payload: e})
      })
  }
}

export function signOut() {
  return (dispatch) => {
    firebase.auth().signOut().then(() => {
      dispatch({type: 'auth/clearCurrentUser'})
    })
  }
}

export function setUsername(uid, username) {
  return (dispatch) => {
    dispatch({
      type: 'auth/setUserProp',
      meta: { prop: 'username' },
      payload: username
    })

    dbFns.setUsername(uid, username)
  }
}

export function setLastActiveBoardId(uid, boardId) {
  return (dispatch) => {
    dispatch({
      type: 'auth/setUserProp',
      meta: { prop: 'lastActiveBoardId' },
      payload: boardId
    })

    dbFns.setLastActiveBoardId(uid, boardId)
  }
}

// Reducer:

export default function auth(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'auth/accountCreationBegin':
      draft.accountCreation.isPending = true
      return draft

      case 'auth/accountCreationSuccess':
      // Don't do anything here -- we need to wait for FirebaseAuthStateWatcher
      // to return the newly signed-in user.
      return draft

      case 'auth/accountCreationFailure':
      draft.accountCreation.isPending = false
      draft.accountCreation.error = action.payload
      return draft

      case 'auth/signinBegin':
      draft.signin.isPending = true
      return draft

      case 'auth/signinSuccess':
      // Don't do anything here -- we need to wait for FirebaseAuthStateWatcher
      // to return the newly signed-in user.
      return draft

      case 'auth/signinFailure':
      draft.signin.isPending = false
      draft.signin.error = action.payload
      return draft

      case 'auth/setCurrentUser':
      // The user could be signing in *or* signing up, but either way we need
      // to wait until we receive this new auth state in order to redirect them.
      draft.signin.isPending = false
      draft.signin.isSuccess = true
      draft.accountCreation.isPending = false
      draft.accountCreation.isSuccess = true

      draft.authStateReceived = true
      draft.currentUser = action.payload
      return draft

      case 'auth/setUserProp':
      draft.currentUser[action.meta.prop] = action.payload
      return draft

      case 'auth/clearCurrentUser':
      draft.authStateReceived = true
      draft.currentUser = {}
      draft.accountCreation.isSuccess = false
      draft.signin.isSuccess = false
      return draft

      default:
      return draft
    }
  })
}
