import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Board } from '../../components/Board'
import { FlexContainer, LeftColumn, RightColumn } from '../../components/layout'
import { useDispatch } from 'react-redux'
import SetupHeader from '../../components/SetupHeader'

export default function Finish({board}) {
  const [showClickTargets, setShowClickTargets] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const joinURL = window.location.origin + '/boards/' + board.data.id

  function nextStep() {
    dispatch({
      type: 'boards/setProp',
      meta: {
        boardId: board.data.id,
        prop: 'setupComplete'
      },
      payload: true
    })

    history.push(`/boards/${board.data.id}`)
  }

  return (
    <div>
      <SetupHeader
        board={board}
        active='finish'
      />

      <div className='px2 lg-px3'>
        <p className='max-width-3'>
          Congrats! You have successfully configured your board. Here's a
          chance to configure some final options before we get to the fun stuff.
        </p>

        <FlexContainer>
          <LeftColumn>
            <label className='label'>
              <input
                type='checkbox'
                checked={board.data.displayOptions.showGrid}
                onChange={(e) => {
                  dispatch({
                    type: 'boards/setDisplayOptionsProp',
                    meta: { boardId: board.data.id, prop: 'showGrid' },
                    payload: e.target.checked
                  })
                }}
              />

              Show grid labels on board
            </label>

            <label className='label' htmlFor='bgColor'>
              Background color
            </label>
            <input
              type='text'
              className='input'
              id='bgColor'
              value={board.data.displayOptions.bgColor}
              onChange={(e) => {
                dispatch({
                  type: 'boards/setDisplayOptionsProp',
                  meta: { boardId: board.data.id, prop: 'bgColor' },
                  payload: e.target.value
                })
              }}
              />

            <label className='label'>Grid click target size</label>
            <input
              className='input'
              type='range'
              value={board.data.displayOptions.clickTargetSizeAdjustment}
              min={-200}
              max={200}
              step={1}
              onMouseDown={() => setShowClickTargets('grid')}
              onMouseUp={() => setShowClickTargets(null)}
              onChange={(e) => {
                dispatch({
                  type: 'boards/setDisplayOptionsProp',
                  meta: {
                    boardId: board.data.id,
                    prop: 'clickTargetSizeAdjustment'
                  },
                  payload: e.target.value
                })
              }}
            />

            <label className='label'>Screw-on click target size</label>
            <input
              className='input'
              type='range'
              value={board.data.displayOptions.screwOnClickTargetSizeAdjustment}
              min={-200}
              max={200}
              step={1}
              onMouseDown={() => setShowClickTargets('screwon')}
              onMouseUp={() => setShowClickTargets(null)}
              onChange={(e) => {
                dispatch({
                  type: 'boards/setDisplayOptionsProp',
                  meta: {
                    boardId: board.data.id,
                    prop: 'screwOnClickTargetSizeAdjustment'
                  },
                  payload: e.target.value
                })
              }}
            />

            <label className='label'>Public Visibility</label>
            <label>
              <input
                type='checkbox'
                checked={board.data.public}
                onChange={(e) => {
                  dispatch({
                    type: 'boards/setProp',
                    meta: { boardId: board.data.id, prop: 'public' },
                    payload: e.target.checked
                  })
                }}
              />

              Allow any BoardCat user to find and join this board
            </label>

            <label className='label pt2'>Join URL</label>
            <div>
              If you would like to invite other users to join this board, simply
              share this URL with them:

              <input type='text' className='input' value={joinURL} readOnly={true} />
            </div>

            <label className='label pt2'>LED System</label>
            <label>
              <input
                type='checkbox'
                checked={!!board.data.ledEnabled}
                onChange={(e) => {
                  dispatch({
                    type: 'boards/setProp',
                    meta: { boardId: board.data.id, prop: 'ledEnabled' },
                    payload: e.target.checked
                  })
                }}
              />

              I am using the BoardCat LED System
            </label>

            <div className='pt2'>
              <button onClick={nextStep} className='btn btn-primary'>
                All done →
              </button>
            </div>
          </LeftColumn>
          <RightColumn>
            <Board
              showClickTargets={showClickTargets}
              board={board}
              onTNutClick={() => {}}
              />
          </RightColumn>
        </FlexContainer>
      </div>
    </div>
  )
}
