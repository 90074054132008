import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ServiceWorkerUpdateWatcher from './components/ServiceWorkerUpdateWatcher'
import FirebaseAuthStateWatcher from './components/FirebaseAuthStateWatcher'
import PreventUnloadWithUnsavedChanges from './components/PreventUnloadWithUnsavedChanges'
import Root from './pages/Root'
import Home from './pages/Home'
import JoinBoard from './pages/JoinBoard'
import Settings from './pages/Settings'
import Signup from './pages/Signup'
import Signin from './pages/Signin'
import PreCreationRoutes from './routes/PreCreationRoutes'
import BoardRoutes from './routes/BoardRoutes'
import ProtectedRoute from './components/ProtectedRoute'
import NotFoundPage from './components/NotFoundPage'
import { useSelector } from 'react-redux'

function App() {
  const currentUser = useSelector(s => s.auth.currentUser)
  const currentUserUid = currentUser && currentUser.uid

  return (
    <ServiceWorkerUpdateWatcher>
      <FirebaseAuthStateWatcher>
        <PreventUnloadWithUnsavedChanges>
          <Switch>
            <Route exact path="/"><Root /></Route>
            <ProtectedRoute fn={() => !!currentUserUid} path="/home"><Home /></ProtectedRoute>
            <ProtectedRoute fn={() => !!currentUserUid} path="/pre-creation"><PreCreationRoutes /></ProtectedRoute>
            <ProtectedRoute fn={() => !!currentUserUid} path="/settings"><Settings /></ProtectedRoute>
            <ProtectedRoute fn={() => !!currentUserUid} path="/join-board"><JoinBoard /></ProtectedRoute>
            <ProtectedRoute fn={() => !currentUserUid} path="/signup"><Signup /></ProtectedRoute>
            <ProtectedRoute fn={() => !currentUserUid} path="/signin"><Signin /></ProtectedRoute>
            <ProtectedRoute fn={() => !!currentUserUid} path="/boards/:id"><BoardRoutes /></ProtectedRoute>
            <Route path="/"><NotFoundPage /></Route>
          </Switch>
        </PreventUnloadWithUnsavedChanges>
      </FirebaseAuthStateWatcher>
    </ServiceWorkerUpdateWatcher>
  )
}

export default App
