import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Stage, Layer, Rect, Image, Text } from 'react-konva'
import { TNUT_SIZE, TNUT_CIRCLE_SIZE } from '../constants'
import {
  pxToBoardUnits,
  boardUnitsToPx,
  getBoardGrid,
  boardSettingsToTNutLocations,
  sortEditingTnutFirst
} from '../utilities'
import useImage from 'use-image'
import { map, find } from 'lodash'

export function Board({
  board,
  setCursor,
  disableShowGrid,
  onTNutClick,
  problem,
  editingTNutIds,
  editingProblemTnutId,
  onScrewOnMove,
  showHolds,
  tnutsNotCreatedYet,
  showClickTargets,
  tnutStyle
}) {
  const boardData = board.data
  const holdImages = board.subcollections.holdImages.data
  const containerEl = useRef(null)
  const boardWidth = boardUnitsToPx(boardData.dimensions.width, boardData.dimensions.units)
  const boardHeight = boardUnitsToPx(boardData.dimensions.height, boardData.dimensions.units)
  const calculatedBoardDimensions = useBoardDimensions(containerEl, boardWidth, boardHeight)
  const showGridLabels = disableShowGrid ? false : boardData.displayOptions.showGrid

  const movingScrewOn = editingTNutIds.length === 1 &&
                        !!onScrewOnMove &&
                        find(boardData.tnuts, { screwOn: true, id: editingTNutIds[0] })

  return (
    <div ref={containerEl}>
      <Stage
        width={calculatedBoardDimensions.stageWidth}
        height={calculatedBoardDimensions.stageHeight}
        scale={{x: calculatedBoardDimensions.scale, y: calculatedBoardDimensions.scale}}
        style={calculatedBoardDimensions.loaded ? {} : {display: 'none'}}
      >
        <Layer hitGraphEnabled={false}>
          <Background bgColor={boardData.displayOptions.bgColor} width={boardWidth} height={boardHeight} />
          {showGridLabels ? <GridLabels tnuts={boardData.tnuts} dimensions={boardData.dimensions} /> : null}
          {
            tnutsNotCreatedYet ?
              <TNutsFromSettings tnutStyle={tnutStyle} dimensions={boardData.dimensions} /> :
              <TNuts tnutStyle={tnutStyle} dimensions={boardData.dimensions} tnuts={boardData.tnuts} />
          }
          <EditingTNutCircles dimensions={boardData.dimensions} tnuts={boardData.tnuts} editingTNutIds={editingTNutIds} />
          {problem ? <Problem dimensions={boardData.dimensions} tnuts={boardData.tnuts} problem={problem} editingProblemTnutId={editingProblemTnutId} /> : null}
        </Layer>

        {showHolds ? <HoldImageLayer dimensions={boardData.dimensions} holdImages={holdImages} tnuts={boardData.tnuts} holds={boardData.holds} /> : null}

        {
          onTNutClick ?
            <BoardInteractionLayer
              showClickTargets={showClickTargets}
              clickTargetSizeAdjustment={boardData.displayOptions.clickTargetSizeAdjustment || 0}
              screwOnClickTargetSizeAdjustment={boardData.displayOptions.screwOnClickTargetSizeAdjustment || 0}
              holds={boardData.holds}
              showHolds={showHolds}
              tnuts={boardData.tnuts}
              dimensions={boardData.dimensions}
              onTNutClick={onTNutClick} /> : null
        }

        {
          movingScrewOn ?
            <ScrewOnMoveController
              dimensions={boardData.dimensions}
              movingScrewOn={movingScrewOn}
              holds={boardData.holds}
              onScrewOnMove={onScrewOnMove}
              setCursor={setCursor}
            /> :
            null
        }
      </Stage>
    </div>
  )
}

Board.defaultProps = {
  editingTNutIds: [],
  showHolds: true,
  showClickTargets: false,
  tnutStyle: 'light'
}

function ScrewOnMoveController({
  setCursor,
  dimensions,
  holds,
  movingScrewOn,
  setScrewOnLocation,
  onScrewOnMove
}) {
  let hold = movingScrewOn.holdId && find(holds, { id: movingScrewOn.holdId })
  if (!hold) return null;

  const originalX = boardUnitsToPx(movingScrewOn.x, dimensions.units)
  const originalY = boardUnitsToPx(movingScrewOn.y, dimensions.units)

  return (
    <Layer>
      <Rect
        x={originalX}
        y={originalY}
        offsetX={TNUT_CIRCLE_SIZE / 2}
        offsetY={TNUT_CIRCLE_SIZE / 2}
        width={TNUT_CIRCLE_SIZE}
        height={TNUT_CIRCLE_SIZE}
        cornerRadius={TNUT_CIRCLE_SIZE}
        draggable={true}
        onMouseenter={e => setCursor('move')}
        onMouseleave={e => setCursor('default')}
        onDragMove={(e) => {
          e.target.setFill('#aaa')
          e.target.setOpacity(0.7)
        }}
        onDragEnd={(e) => {
          e.target.setFill('')
          e.target.setOpacity(1)

          onScrewOnMove(
            pxToBoardUnits(e.target.x(), dimensions.units),
            pxToBoardUnits(e.target.y(), dimensions.units)
          )
        }}
      />
    </Layer>
  )
}

export function MockBoard({board, hold, onDragEnd}) {
  const boardData = board.data
  const holdImages = board.subcollections.holdImages.data
  const boardWidth = 250
  const boardHeight = 250
  const boardScale = 0.4
  const fakeTNut = {
    x: pxToBoardUnits(125, boardData.dimensions.units),
    y: pxToBoardUnits(125, boardData.dimensions.units),
    orientation: 0
  }

  return (
    <Stage
      width={boardWidth * boardScale}
      height={boardHeight * boardScale}
      scale={{x: boardScale, y: boardScale}}
    >
      <Layer hitGraphEnabled={false}>
        <Background width={boardWidth} height={boardHeight} />
        <TNut opacity={1} fill={'red'} dimensions={boardData.dimensions} x={fakeTNut.x} y={fakeTNut.y} />
      </Layer>

      <Layer>
        <HoldImage
          tnut={fakeTNut}
          hold={hold}
          dimensions={boardData.dimensions}
          holdImageDocument={holdImages[hold.imageId]}
          onDragEnd={onDragEnd}
          opacity={0.7}
        />
      </Layer>
    </Stage>
  )
}

const Background = React.memo(({bgColor, width, height}) => {
  return (
    <Rect
      x={0}
      y={0}
      width={width}
      height={height}
      fill={bgColor || '#ccc'}
    />
  )
})

const TNuts = React.memo(({tnutStyle, dimensions, tnuts}) => {
  return map(tnuts, (tnut) => {
    if (tnut.screwOn) return;
    return <TNut style={tnutStyle} dimensions={dimensions} x={tnut.x} y={tnut.y} key={tnut.id} />
  })
})

const EditingTNutCircles = React.memo(({dimensions, tnuts, editingTNutIds}) => {
  return map(editingTNutIds, (id) => {
    let tnut = tnuts[id]
    return <TNutCircle dimensions={dimensions} x={tnut.x} y={tnut.y} key={id} color='#0074D9' />
  })
})

const HoldImageLayer = React.memo(({dimensions, holdImages, tnuts, holds}) => {
  return (
    <Layer hitGraphEnabled={false}>
      {
        map(tnuts, (tnut) => {
          let hold = find(holds, { id: tnut.holdId })

          return (
            hold ?
              <HoldImage
                dimensions={dimensions}
                holdImageDocument={hold && holdImages[hold.imageId]}
                tnut={tnut}
                hold={hold}
                key={tnut.id}
              /> : null
          )
        })
      }
    </Layer>
  )
})

const TNutsFromSettings = React.memo(({tnutStyle, dimensions}) => {
  return boardSettingsToTNutLocations(dimensions).map((tnut, i) => {
    return <TNut style={tnutStyle} dimensions={dimensions} x={tnut.x} y={tnut.y} key={i} />
  })
})

const BoardInteractionLayer = React.memo(({
  showClickTargets,
  clickTargetSizeAdjustment,
  screwOnClickTargetSizeAdjustment,
  holds,
  showHolds,
  tnuts,
  dimensions,
  onTNutClick
}) => {
  return (
    <Fragment>
      <LessSpecificBoardInteractionLayer
        showClickTargets={showClickTargets}
        clickTargetSizeAdjustment={clickTargetSizeAdjustment}
        screwOnClickTargetSizeAdjustment={screwOnClickTargetSizeAdjustment}
        holds={holds}
        showHolds={showHolds}
        tnuts={tnuts}
        dimensions={dimensions}
        onTNutClick={onTNutClick}
      />

      <MoreSpecificBoardInteractionLayer
        showClickTargets={showClickTargets}
        clickTargetSizeAdjustment={clickTargetSizeAdjustment}
        screwOnClickTargetSizeAdjustment={screwOnClickTargetSizeAdjustment}
        holds={holds}
        showHolds={showHolds}
        tnuts={tnuts}
        dimensions={dimensions}
        onTNutClick={onTNutClick}
      />
    </Fragment>
  )
})

// First layer, least specific:
//   - Click target is the size of the tnut circle
//   - No click target if screw-on
const LessSpecificBoardInteractionLayer = React.memo(({
  showClickTargets,
  clickTargetSizeAdjustment,
  holds,
  showHolds,
  tnuts,
  dimensions,
  onTNutClick
}) => {
  let largeClickTargetSize = TNUT_CIRCLE_SIZE + clickTargetSizeAdjustment

  return (
    <Layer>
      {
        map(tnuts, (tnut, id) => {
          let clickHandler = (e) => { onTNutClick(tnut, e) }
          if (tnut.screwOn) return;

          return (
            <Rect
              x={boardUnitsToPx(tnut.x, dimensions.units)}
              y={boardUnitsToPx(tnut.y, dimensions.units)}
              offsetX={largeClickTargetSize / 2}
              offsetY={largeClickTargetSize / 2}
              width={largeClickTargetSize}
              height={largeClickTargetSize}
              onClick={clickHandler}
              onTap={clickHandler}
              key={tnut.id}
              fill={showClickTargets === 'grid' ? 'red' : null}
              opacity={0.5}
            />
          )
        })
      }
    </Layer>
  )
})

// Second layer, more specific:
//   - If showHolds, click target is exact size of hold image.
//   - Otherwize, click target is slightly larger than the size of a tnut.
//     - No click target if screw-on
const MoreSpecificBoardInteractionLayer = React.memo(({
  showClickTargets,
  screwOnClickTargetSizeAdjustment,
  holds,
  showHolds,
  tnuts,
  dimensions,
  onTNutClick
}) => {
  return (
    <Layer>
      {
        map(tnuts, (tnut, id) => {
          // Not showing holds, so skip any screw-ons
          if (tnut.screwOn && !showHolds) return;

          let clickHandler = (e) => { onTNutClick(tnut, e) }
          let hold = tnut.holdId && find(holds, { id: tnut.holdId })
          let slightlyLargerThanTNutSize = TNUT_SIZE + 20

          let offsetX, offsetY, width, height;

          // Screw-on
          if (tnut.screwOn) {
            offsetX = boardUnitsToPx(hold.boltLocationX, dimensions.units) + (screwOnClickTargetSizeAdjustment / 2)
            offsetY = boardUnitsToPx(hold.boltLocationY, dimensions.units) + (screwOnClickTargetSizeAdjustment / 2)
            width = hold.width + screwOnClickTargetSizeAdjustment
            height = hold.height + screwOnClickTargetSizeAdjustment

          // Bolt-on
          } else if (hold) {
            offsetX = boardUnitsToPx(hold.boltLocationX, dimensions.units)
            offsetY = boardUnitsToPx(hold.boltLocationY, dimensions.units)
            width = hold.width
            height = hold.height

          // No hold
          } else {
            offsetX = slightlyLargerThanTNutSize / 2
            offsetY = slightlyLargerThanTNutSize / 2
            width = slightlyLargerThanTNutSize
            height = slightlyLargerThanTNutSize
          }

          return (
            <Rect
              x={boardUnitsToPx(tnut.x, dimensions.units)}
              y={boardUnitsToPx(tnut.y, dimensions.units)}
              offsetX={offsetX}
              offsetY={offsetY}
              width={width}
              height={height}
              rotation={tnut.orientation}
              key={tnut.id}
              onClick={clickHandler}
              onTap={clickHandler}
              fill={(tnut.screwOn && showClickTargets === 'screwon') || (!tnut.screwOn && showClickTargets === 'grid') ? 'red' : null}
              opacity={0.5}
            />
          )
        })
      }
    </Layer>
  )
})

const Problem = React.memo(({dimensions, tnuts, problem, editingProblemTnutId}) => {
  return map(sortEditingTnutFirst(tnuts, editingProblemTnutId), (tnut, id) => {
    let color = null
    let labelText = null
    let holdType = problem.holdsMap[tnut.id]
    let showLabel = (tnut.id === editingProblemTnutId)

    if (holdType === 'finish') {
      color = '#FF4136'
      labelText = 'Finish'
    } else if (holdType === 'start') {
      color = '#2ECC40'
      labelText = 'Start'
    } else if (holdType === 'middle') {
      color = '#0074D9'
      labelText = 'Middle'
    } else if (holdType === 'foot') {
      color = '#F012BE'
      labelText = 'Foot only'
    }

    return (
      color ?
        <TNutCircle
          dimensions={dimensions}
          x={tnut.x}
          y={tnut.y}
          color={color}
          key={tnut.id}
          labelText={showLabel && labelText}
        /> : null
    )
  })
})

const GridLabels = React.memo(({tnuts, dimensions}) => {
  const { columns, rows } = getBoardGrid(tnuts)

  const namedColumns = map(columns, ([x, text]) => {
    return {
      x: boardUnitsToPx(x, dimensions.units) - 62,
      y: 40,
      text: text,
      align: 'center',
      width: 125
    }
  })

  const namedRows = map(rows, ([y, text]) => {
    return {
      x: 0,
      y: boardUnitsToPx(y, dimensions.units) - 45,
      text: text,
      align: 'right',
      width: 120
    }
  })

  const labels = [
    ...namedColumns,
    ...namedRows
  ]

  return map(labels, (labelProps) =>
    <Text
      {...labelProps}
      fontSize={90}
      fontFamily={'Helvetica Neue'}
      fontStyle={600}
      fill={'#fff'}
      key={[labelProps.x, labelProps.y]} />
  )
})

const HoldImage = React.memo(({
  dimensions,
  holdImageDocument,
  hold,
  tnut,
  opacity,
  onDragEnd
}) => {
  const [image] = useImage(holdImageDocument && holdImageDocument.base64data)

  const originalX = boardUnitsToPx(tnut.x, dimensions.units)
  const originalY = boardUnitsToPx(tnut.y, dimensions.units)

  return (
    <Image
      image={image}
      x={originalX}
      y={originalY}
      offsetX={boardUnitsToPx(hold.boltLocationX, dimensions.units)}
      offsetY={boardUnitsToPx(hold.boltLocationY, dimensions.units)}
      width={hold.width}
      height={hold.height}
      rotation={tnut.orientation}
      opacity={opacity}
      draggable={!!onDragEnd}
      onDragEnd={(e) => {
        let deltaX = ((e.target.x() - originalX) / hold.scale)
        let deltaY = ((e.target.y() - originalY) / hold.scale)

        e.target.x(originalX)
        e.target.y(originalY)

        onDragEnd({
          deltaX: deltaX,
          deltaY: deltaY
        })
      }}
      scale={{
        x: hold.scale,
        y: hold.scale
      }}
    />
  )
})

const TNutCircle = React.memo(({dimensions, x, y, color, labelText}) => {
  return (
    <Fragment>
      <Rect
        x={boardUnitsToPx(x, dimensions.units)}
        y={boardUnitsToPx(y, dimensions.units)}
        offsetX={TNUT_CIRCLE_SIZE / 2}
        offsetY={TNUT_CIRCLE_SIZE / 2}
        width={TNUT_CIRCLE_SIZE}
        height={TNUT_CIRCLE_SIZE}
        cornerRadius={TNUT_CIRCLE_SIZE}
        strokeWidth={20}
        stroke={color}
      />

      {
        labelText ? (
          <Fragment>
            <Rect
              width={350}
              height={100}
              offsetY={-80}
              offsetX={175}
              x={boardUnitsToPx(x, dimensions.units)}
              y={boardUnitsToPx(y, dimensions.units)}
              fill={color}
              cornerRadius={50}
            />
            <Text
              x={boardUnitsToPx(x, dimensions.units)}
              y={boardUnitsToPx(y, dimensions.units)}
              offsetY={-95}
              offsetX={175}
              text={labelText}
              fontSize={70}
              fontFamily={'Helvetica Neue'}
              width={350}
              align={'center'}
              fill={'#fff'}
            />
          </Fragment>
        ): null
      }
    </Fragment>
  )
})

const TNut = React.memo(({style, fill, dimensions, x, y}) => {
  let opacity;

  if (style === 'dark') {
    opacity = 0.6
  } else if (style === 'light') {
    opacity = 0.3
  } else {
    throw new Error(`unsupported value for <TNut> style prop: ${style}`)
  }

  return (
    <Rect
      x={boardUnitsToPx(x, dimensions.units)}
      y={boardUnitsToPx(y, dimensions.units)}
      offsetX={TNUT_SIZE / 2}
      offsetY={TNUT_SIZE / 2}
      width={TNUT_SIZE}
      height={TNUT_SIZE}
      cornerRadius={TNUT_SIZE}
      fill={fill}
      opacity={opacity}
    />
  )
})

TNut.defaultProps = {
  fill: '#000',
  style: 'light'
}

function useBoardDimensions(containerEl, boardWidth, boardHeight) {
  const [boardDimensions, setBoardDimensions] = useState({
    scale: 1,
    loaded: false,
    stageHeight: 0,
    stageWidth: 0
  })

  useEffect(() => {
    function calculateBoardDimensions() {
      let maxWidth = containerEl.current.offsetWidth
      let maxHeight = window.innerHeight -
                        containerEl.current.parentElement.parentElement.offsetTop -
                        15 // padding

      // when we're not in multi-column view, don't constrain on height
      if (window.innerWidth < 1024) {
        maxHeight = window.innerHeight
      }

      // First constrain by width:
      let newScale = maxWidth / boardWidth
      let newWidth = boardWidth * newScale
      let newHeight = boardHeight * newScale

      // Constrain by height if necessary
      if (newHeight > maxHeight) {
        newScale = maxHeight / boardHeight
        newWidth = boardWidth * newScale
        newHeight = boardHeight * newScale
      }

      setBoardDimensions({
        scale: newScale,
        stageWidth: newWidth,
        stageHeight: newHeight,
        loaded: true
      })
    }

    calculateBoardDimensions()
    window.addEventListener('resize', calculateBoardDimensions)
    return () => {
      window.removeEventListener('resize', calculateBoardDimensions)
    }
  }, [containerEl, boardWidth, boardHeight])

  return boardDimensions
}
