import { each, find, padStart } from 'lodash'
import webController from './web'
import reactNativeController from './reactNative'

// Platform detection:

const bluetoothController = detectBluetoothController()

function detectBluetoothController() {
  if (navigator.bluetooth) return webController;
  if (window.ReactNativeWebView) return reactNativeController;
  return null;
}

export const bluetoothSupported = !!bluetoothController

// Public API:

export function connect({onConnecting, onConnected, onErrorConnecting, onDisconnected}) {
  if (!bluetoothController) return;
  bluetoothController.connect({onConnecting, onConnected, onErrorConnecting, onDisconnected})
}

export function disconnect() {
  if (!bluetoothController) return;
  bluetoothController.disconnect()
}

export function setLedsByProblem(board, problem) {
  if (!bluetoothController) return;
  bluetoothController.write(ledMapToString(problemToLedMap(board, problem)))
}

export function clearLeds() {
  if (!bluetoothController) return;
  bluetoothController.clear()
}

export function setLedsByIdx(ledMap) {
  if (!bluetoothController) return;
  bluetoothController.write(ledMapToString(ledMap))
}

// Private methods:

function problemToLedMap(board, problem) {
  let ledMap = {}

  each(problem.holdsMap, (holdType, tnutId) => {
    let tnut = find(board.data.tnuts, { id: tnutId })

    if (tnut) {
      ledMap[tnut.ledIdx] = holdTypeToLedChar(holdType)
    }
  })

  return ledMap
}

function holdTypeToLedChar(holdType) {
  return {
    'start': 'g',
    'middle': 'b',
    'finish': 'r',
    'foot': 'p'
  }[holdType]
}

function ledMapToString(ledMap) {
  let str = ''

  each(ledMap, (v, k) => {
    str = str + padStart(k.toString(), 3, '0') + v
  })

  return str
}
