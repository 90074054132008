import React, { useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { useAutofocusInput } from '../utilities'
import { useSelector, useDispatch } from 'react-redux'
import { signIn as signInAction } from '../reducers/auth'
import BasicPage from '../components/BasicPage'

export default function Signin() {
  const signinState = useSelector(s => s.auth.signin)
  const [form, setForm] = useState({
    username: '',
    email: '',
    password: ''
  })
  const dispatch = useDispatch()
  const emailInput = useAutofocusInput()

  // Redirect home after sign in
  if (signinState.isSuccess) {
    return <Redirect to='/' />
  }

  function handleSubmit(e) {
    e.preventDefault()
    dispatch(signInAction(form))
  }

  return (
    <BasicPage title='Sign in to BoardCat'>
      <form className='left-align max-width-2 mx-auto' onSubmit={handleSubmit}>
        <label htmlFor='email' className='label'>Email</label>
        <input
          ref={emailInput}
          id='email'
          type='email'
          value={form.email}
          className='input'
          onChange={e => setForm({ ...form, email: e.target.value })}
          required
        />

        <label htmlFor='password' className='label'>Password</label>
        <input
          id='password'
          type='password'
          value={form.password}
          className='input'
          onChange={e => setForm({ ...form, password: e.target.value })}
          required
        />

        {
          signinState.error ?
            <p>{signinState.error.message}</p> : null
        }

        <Link to='/' className='btn btn-secondary'>Back</Link>

        &nbsp;

        <button
          className='btn btn-primary'
          disabled={signinState.isPending || (!form.email || !form.password)}
        >{signinState.isPending ? 'Signing in…' : 'Sign in'}</button>
      </form>
    </BasicPage>
  )
}
