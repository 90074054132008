import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MockBoard } from '../../components/Board'
import MultipleValuesInput from '../../components/MultipleValuesInput'
import { FlexContainer, LeftColumn, RightColumn } from '../../components/layout'
import { useSelector, useDispatch } from 'react-redux'
import FileBase64 from '../../components/FileBase64'
import SetupHeader from '../../components/SetupHeader'
import {
  at,
  size,
  map,
  includes,
  filter,
  indexOf,
  range,
  uniq,
  values,
  omit,
  each
} from 'lodash'
import {
  boardUnitsToPx,
  pxToBoardUnits,
  sortAlphabetically,
  generateId,
  confirmAction
} from '../../utilities'
import RangeAndNumberInput from '../../components/RangeAndNumberInput'
import dbFns from '../../dbFns'
import { batchUploadHoldImages } from '../../reducers/ui'

export default function Holds({board}) {
  return (
    <div>
      <SetupHeader
        board={board}
        active='holds'
      />

      <div className='px2 lg-px3'>
        <p className='max-width-3'>
          The second step is to add images of your holds. These can usually be
          found from the hold manufacturer, but you can also take photographs
          and crop them. For BoardCat to work properly, the images must be in
          PNG format and have a transparent background. Stick with it, this is
          usually the most labor-intensive step, But the end result will be worth it.
        </p>

        <p className='max-width-3'>
          Please ensure that the images are in PNG format and <strong>no bigger
          than 100x100 pixels.</strong> Remember, these are individual holds
          and they're going to be displayed pretty small.
        </p>

        <p className='max-width-3 h6'>
          <em>
            Tip: BoardCat can automatically assign your board a lettering/numbering
            scheme, so that each hold has a name like "A2" or "K15". When uploading hold
            images, if you name your files so that they match this naming scheme, BoardCat will
            automatically place them in the correct location on your board.
            (If you need to see what this grid looks like, click on "set board" in the navigation.)
          </em>
        </p>

        <UploadForm board={board} />

        {size(board.data.holds) > 0 ? <EditHoldsInterface board={board} /> : null }
      </div>
    </div>
  )
}

function UploadForm({board}) {
  const [uploadError, setUploadError] = useState()
  const dispatch = useDispatch()
  const batchHoldImageUploadState = useSelector(s => s.ui.batchHoldImageUpload)

  return (
    <div className='pb2 lg-col-6'>
      <div className='block border rounded border--gray col-12 p2'>
        {
          batchHoldImageUploadState.isUploading ?
            (
              <span>Uploading...</span>
            ) :
            (
              <Fragment>
                <FileBase64
                  className=''
                  id='files'
                  multiple={true}
                  onDone={(files, rejectedFiles, e) => {
                    // Clear input
                    e.target.value = null

                    if (rejectedFiles.length > 0) {
                      setUploadError(`${rejectedFiles.length} ${rejectedFiles.length === 1 ? 'file was' : 'files were'} not successfully uploaded`)
                    } else {
                      setUploadError(null)
                    }

                    if (files.length > 0) {
                      filesToHolds(files, board).then((holds) => {
                        // Save the holds *excluding their image* to the boards collection
                        dispatch({
                          type: 'holds/createFromFiles',
                          meta: {
                            boardId: board.data.id,
                          },
                          payload: map(holds, (h) => omit(h, 'base64data'))
                        })

                        let batchArr = map(holds, (h) => [board.data.id, h.imageId, h.base64data])

                        // Save the images to the holdImages collection, then refresh the data
                        dispatch(batchUploadHoldImages(board.data.id, batchArr))
                      })
                    }
                  }}
                />
                {batchHoldImageUploadState.error ? <div>{batchHoldImageUploadState.error}</div> : null}
                {uploadError ? <div>{uploadError}</div> : null}
              </Fragment>
            )
        }
      </div>
    </div>
  )
}

function EditHoldItem({hold, board, isEditing, toggleActive}) {
  const dispatch = useDispatch()

  function onDragEnd({deltaX, deltaY}) {
    dispatch({
      type: 'holds/setProp',
      meta: {
        boardId: board.data.id,
        holdId: hold.id,
        prop: 'boltLocationX'
      },
      payload: pxToBoardUnits(
        boardUnitsToPx(hold.boltLocationX, board.data.dimensions.units) - deltaX,
        board.data.dimensions.units
      )
    })

    dispatch({
      type: 'holds/setProp',
      meta: {
        boardId: board.data.id,
        holdId: hold.id,
        prop: 'boltLocationY'
      },
      payload: pxToBoardUnits(
        boardUnitsToPx(hold.boltLocationY, board.data.dimensions.units) - deltaY,
        board.data.dimensions.units
      )
    })
  }

  return (
    <li
      className={`HoldItem col-4 md-col-3 lg-col-2 p1 ${isEditing ? 'HoldItem--editing' : ''}`}
      onClick={(e) => { if (e.target.nodeName !== 'CANVAS') { toggleActive(e) } }}
    >
      <div className='h4'>
        {hold.name}
      </div>

      <MockBoard board={board} hold={hold} onDragEnd={onDragEnd} />
    </li>
  )
}

function EditHoldForm({hold, board, onClose}) {
  const dispatch = useDispatch()

  return (
    <div>
      <label className='label' htmlFor='name'>Name</label>
      <input
        className='input'
        id='name'
        type='text'
        value={hold.name}
        onChange={(e) => {
          dispatch({
            type: 'holds/setProp',
            meta: {
              boardId: board.data.id,
              holdId: hold.id,
              prop: 'name'
            },
            payload: e.target.value
          })
        }}
      />

      <label className='label' htmlFor='scale'>Scale</label>
      <RangeAndNumberInput
        id='scale'
        min={0.1}
        max={5}
        step={0.1}
        value={hold.scale}
        onChange={(e) => {
          dispatch({
            type: 'holds/setProp',
            meta: {
              boardId: board.data.id,
              holdId: hold.id,
              prop: 'scale'
            },
            payload: e.target.value
          })
        }}
      />

      <label className='label' htmlFor='boltLocationX'>Bolt location x</label>
      <RangeAndNumberInput
        id='boltLocationX'
        step={0.1}
        min={pxToBoardUnits(1, board.data.dimensions.units)}
        max={pxToBoardUnits(hold.width, board.data.dimensions.units)}
        value={hold.boltLocationX}
        onChange={(e) => {
          dispatch({
            type: 'holds/setProp',
            meta: {
              boardId: board.data.id,
              holdId: hold.id,
              prop: 'boltLocationX'
            },
            payload: e.target.value,
          })
        }}
        units={board.data.dimensions.units}
      />

      <label className='label' htmlFor='boltLocationX'>Bolt location y</label>
      <RangeAndNumberInput
        id='boltLocationY'
        step={0.1}
        min={pxToBoardUnits(1, board.data.dimensions.units)}
        max={pxToBoardUnits(hold.height, board.data.dimensions.units)}
        value={hold.boltLocationY}
        onChange={(e) => {
          dispatch({
            type: 'holds/setProp',
            meta: {
              boardId: board.data.id,
              holdId: hold.id,
              prop: 'boltLocationY'
            },
            payload: e.target.value,
          })
        }}
        units={board.data.dimensions.units}
      />

      <label className='label' htmlFor='replaceImage'>Replace image</label>

      <FileBase64
        id='replaceImage'
        className='mb2'
        multiple={false}
        onDone={(files, rejectedFiles, e) => {
          // Clear input
          e.target.value = null

          filesToHolds(files, board).then((holds) => {
            let newHold = holds[0]

            dispatch({
              type: 'holds/replaceImage',
              meta: {
                boardId: board.data.id,
                holdId: hold.id
              },
              payload: newHold
            })

            dbFns.removeHoldImage(board.data.id, hold.imageId)
            dbFns.saveHoldImage(board.data.id, newHold.imageId, newHold.base64data)
          })
        }}
      />

      <button className='btn btn-primary' onClick={onClose}>Done</button>
      &nbsp;or&nbsp;
      <span className='link' onClick={() => {
        if (confirmAction()) {
          onClose()
          dispatch({
            type: 'holds/remove',
            meta: {
              boardId: board.data.id,
              holdId: hold.id
            }
          })
        }
      }}>Delete hold</span>
    </div>
  )
}

function EditMultipleHoldsForm({holds, board, onClose}) {
  const dispatch = useDispatch()
  const scaleVals = map(holds, 'scale')
  const holdsHaveSameScaleVal = uniq(scaleVals).length === 1
  const boltLocationXVals = map(holds, 'boltLocationX')
  const holdsHaveSameBoltLocationXVal = uniq(boltLocationXVals).length === 1
  const boltLocationYVals = map(holds, 'boltLocationY')
  const holdsHaveSameBoltLocationYVal = uniq(boltLocationYVals).length === 1

  return (
    <div>
      {holdsHaveSameScaleVal ?
        <div>
          <label className='label' htmlFor='scale'>Scale</label>
          <RangeAndNumberInput
            id='scale'
            min={0.1}
            max={5}
            step={0.1}
            value={scaleVals[0]}
            onChange={(e) => {
              dispatch({
                type: 'holds/setPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'scale'
                },
                payload: e.target.value
              })
            }}
          />
        </div> :
        <div>
          <label className='label' htmlFor='scale'>Scale</label>

          <MultipleValuesInput
            className='mb1'
            id='scale'
            onDecrease={() => {
              dispatch({
                type: 'holds/incrementPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'scale'
                },
                payload: -0.1
              })
            }}
            onIncrease={() => {
              dispatch({
                type: 'holds/incrementPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'scale'
                },
                payload: +0.1
              })
            }}
          />
        </div>
      }

      {holdsHaveSameBoltLocationXVal ?
        <div>
          <label className='label' htmlFor='boltLocationX'>Bolt location x</label>
          <RangeAndNumberInput
            id='boltLocationX'
            step={0.1}
            min={pxToBoardUnits(1, board.data.dimensions.units)}
            max={pxToBoardUnits(Math.max(...map(holds, 'width')), board.data.dimensions.units)}
            value={boltLocationXVals[0]}
            onChange={(e) => {
              dispatch({
                type: 'holds/setPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'boltLocationX'
                },
                payload: e.target.value
              })
            }}
            units={board.data.dimensions.units}
          />
        </div> :
        <div>
          <label className='label' htmlFor='boltLocationX'>Bolt location x</label>

          <MultipleValuesInput
            className='mb1'
            id='boltLocationX'
            onDecrease={() => {
              dispatch({
                type: 'holds/incrementPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'boltLocationX'
                },
                payload: -0.1
              })
            }}
            onIncrease={() => {
              dispatch({
                type: 'holds/incrementPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'boltLocationX'
                },
                payload: +0.1
              })
            }}
          />
        </div>
      }

      {holdsHaveSameBoltLocationYVal ?
        <div>
          <label className='label' htmlFor='boltLocationX'>Bolt location y</label>
          <RangeAndNumberInput
            id='boltLocationY'
            step={0.1}
            min={pxToBoardUnits(1, board.data.dimensions.units)}
            max={pxToBoardUnits(Math.max(...map(holds, 'height')), board.data.dimensions.units)}
            value={boltLocationYVals[0]}
            onChange={(e) => {
              dispatch({
                type: 'holds/setPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'boltLocationY'
                },
                payload: e.target.value
              })
            }}
            units={board.data.dimensions.units}
          />
        </div> :
        <div>
          <label className='label' htmlFor='boltLocationX'>Bolt location y</label>

          <MultipleValuesInput
            className='mb1'
            id='boltLocationY'
            onDecrease={() => {
              dispatch({
                type: 'holds/incrementPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'boltLocationY'
                },
                payload: -0.1
              })
            }}
            onIncrease={() => {
              dispatch({
                type: 'holds/incrementPropMany',
                meta: {
                  boardId: board.data.id,
                  holdIds: map(holds, 'id'),
                  prop: 'boltLocationY'
                },
                payload: +0.1
              })
            }}
          />
        </div>
      }

      <button className='btn btn-primary' onClick={onClose}>Done</button>
      &nbsp;or&nbsp;
      <span className='link' onClick={() => {
        if (confirmAction()) {
          onClose()

          dispatch({
            type: 'holds/removeMany',
            meta: {
              boardId: board.data.id,
              holdIds: map(holds, 'id')
            }
          })
        }
      }}>Delete these holds</span>
    </div>
  )
}

function EditHoldsInterface({board}) {
  const [editingIds, setEditingIds] = useState([])
  const editingHolds = filter(board.data.holds, (h) => includes(editingIds, h.id))
  const history = useHistory()
  const sortedHolds = sortAlphabetically(values(board.data.holds), 'name')

  function nextStep() {
    history.push(`/boards/${board.data.id}/setup/set`)
  }

  function toggleActive(e, hold) {
    if (includes(editingIds, hold.id)) {
      setEditingIds([])
    } else {
      if (editingIds.length > 0 && e.shiftKey) {
        let sortedHoldIds = map(sortedHolds, 'id')
        let editingIndices = map(editingIds, (id) => indexOf(sortedHoldIds, id))
        let holdIndex = indexOf(sortedHoldIds, hold.id)
        let bothIndices = [...editingIndices, holdIndex]
        let newEditingIndices = range(Math.min(...bothIndices), Math.max(...bothIndices) + 1)

        setEditingIds(at(sortedHoldIds, ...newEditingIndices))
      } else {
        setEditingIds([hold.id])
      }
    }
  }

  return (
    <FlexContainer>
      <LeftColumn sticky={true}>
        {
          editingHolds.length === 0 ?
            <p className='h3 gray'>Choose a hold to adjust its size, bolt location, etc.</p> :
            null
        }

        {
          editingHolds.length === 1 ?
            <EditHoldForm
              board={board}
              hold={editingHolds[0]}
              onClose={() => { setEditingIds([]) }}
            /> : null
        }

        {
          editingHolds.length > 1 ?
            <EditMultipleHoldsForm
              board={board}
              holds={editingHolds}
              onClose={() => { setEditingIds([]) }}
            /> : null
        }

        <div className='pt2'>
          <button onClick={nextStep} className='btn btn-primary'>
            Continue to next step →
          </button>
        </div>
      </LeftColumn>

      <RightColumn>
        <ul className='HoldItemList list-reset flex flex-wrap'>
          {map(sortedHolds, (hold) =>
            <EditHoldItem
              board={board}
              hold={hold}
              key={hold.id}
              isEditing={editingIds && includes(editingIds, hold.id)}
              toggleActive={(e) => toggleActive(e, hold)}
            />
          )}
        </ul>
      </RightColumn>
    </FlexContainer>
  )
}

// Read the files from a <input type=file>, and load each one
// so that we can read its height and width.
async function filesToHolds(files, board) {
  return new Promise((resolve, reject) => {
    let returnArr = []

    each(files, (file) => {
      let img = document.createElement('img')
      img.src = file.base64

      img.addEventListener('load', () => {
        returnArr.push({
          id: generateId(),
          name: file.name.split('.')[0],
          imageId: generateId(),
          base64data: file.base64,
          scale: 1, // TYPICAL_HOLD_SIZE / Math.max(img.width, img.height),
          boltLocationX: pxToBoardUnits(Math.round(img.width / 2), board.data.dimensions.units),
          boltLocationY: pxToBoardUnits(Math.round(img.height / 2), board.data.dimensions.units),
          height: img.height,
          width: img.width
        })

        if (returnArr.length === files.length) {
          resolve(returnArr)
        }
      })
    })
  })
}
