import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function PreventUnloadWithUnsavedChanges(props) {
  const boardUpdateQueued = useSelector(s => s.ui.boardUpdateQueued)
  const problemUpdateQueued = useSelector(s => s.ui.problemUpdateQueued)
  const savesInProgress = useSelector(s => s.ui.savesInProgress)

  useEffect(() => {
    if (
      (
        boardUpdateQueued ||
        problemUpdateQueued ||
        savesInProgress > 0
      ) &&
      !window.PUPPETEER
    ) {
      window.onbeforeunload = (e) => {
        e.preventDefault()
        e.returnValue = ''
      }
    } else {
      window.onbeforeunload = null
    }

    // Not sure this component will ever be unmounted, but this seems like
    // a good idea anyway...
    return () => {
      window.onbeforeunload = null
    }
  }, [boardUpdateQueued, problemUpdateQueued, savesInProgress])

  return props.children
}
