import React, { Fragment, useEffect } from 'react'
import { Route, Redirect, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { canManageProblem } from '../permissions'
import ProtectedRoute from '../components/ProtectedRoute'

import {
  Holds,
  Details,
  Tick
} from '../pages/problems'

export default function ProblemRoutes({board, activeProblem}) {
  const dispatch = useDispatch()
  const params = useParams()
  const problem = board.subcollections.problems.data[params.problemId]
  const currentUser = useSelector(s => s.auth.currentUser)

  useEffect(() => {
    if (problem && problem.id) {
      dispatch({
        type: 'ui/setActiveProblemId',
        payload: problem.id
      })
    }
  }, [problem, dispatch])

  if (!problem) return <Redirect to={`/boards/${board.data.id}`} />;

  return (
    <Fragment>
      <ProtectedRoute
        fn={() => canManageProblem(currentUser, board, problem)}
        path="/boards/:id/problems/:problemId/holds"
      >
        <Holds board={board} problem={problem} />
      </ProtectedRoute>
      <ProtectedRoute
        fn={() => canManageProblem(currentUser, board, problem)}
        path="/boards/:id/problems/:problemId/details"
      >
        <Details board={board} problem={problem} />
      </ProtectedRoute>

      <Route path="/boards/:id/problems/:problemId/tick"><Tick board={board} problem={problem} /></Route>

      <Route exact path="/boards/:id/problems/:problemId">
        <SetActiveProblemAndRedirect board={board} />
      </Route>
    </Fragment>
  )
}

// This is currently used as a way to have a "permalink" to a problem.
// This needs to be fixed.
function SetActiveProblemAndRedirect({board}) {
  return (
    <Redirect to={`/boards/${board.data.id}`} />
  )
}
