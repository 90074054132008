import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'

export default function ServiceWorkerUpdateWatcher(props) {
  const serviceWorkerState = useSelector(s => s.ui.serviceWorker)

  return (
    <Fragment>
      {serviceWorkerState.isUpdated && <ServiceWorkerUpdateAlert />}
      {props.children}
    </Fragment>
  )
}

function ServiceWorkerUpdateAlert() {
  const serviceWorkerState = useSelector(s => s.ui.serviceWorker)
  const [isUpdating, setIsUpdating] = useState()

  function update() {
    if (isUpdating) return;
    setIsUpdating(true)
    const registration = serviceWorkerState.registration;
    const registrationWaiting = registration && registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload()
        }
      })
    } else {
      window.location.reload()
    }
  }

  return (
    <div className='ServiceWorkerUpdateAlert h5'>
      A new version of BoardCat is available.
      &nbsp;
      <span onClick={update} className='link'>{isUpdating ? 'Updating...' : 'Update'}</span>
    </div>
  )
}
