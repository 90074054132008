import React from 'react'
import { Link } from 'react-router-dom'
import BasicPage from '../../components/BasicPage'

const CONCIERGE_SIGN_UP_FORM_LINK = '//forms.gle/a7pZfwwG12fvPryJ6'

export default function Start() {
  return (
    <BasicPage title='Coming soon'>
      <div className='center max-width-2 mx-auto justify'>
        <p>
          Got it, we understand, nobody wants to sit in front of Photoshop for hours.
          Currently the image cropping step is a bit laborious, but we're working
          to make it faster so that we can onboard users as quick as possible.
        </p>

        <p>
          If you'd like to secure your place in line,
          please <a href={CONCIERGE_SIGN_UP_FORM_LINK} target='_blank' rel='noopener noreferrer'>fill out this form</a> and
          we will be in touch with you when we are ready to set up your board.
          (Note: the form will require signing in with Google so that you can )
        </p>

        <div className='py1 center'>
          <Link to='/home' className='btn btn-secondary'>Back home</Link>
        </div>
      </div>
    </BasicPage>
  )
}
