import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import BasicPage from '../components/BasicPage'
import { useSelector, useDispatch } from 'react-redux'
import { signOut } from '../reducers/auth'
import { formatTimestamp } from '../utilities'

export default function Settings() {
  return (
    <BasicPage title='BoardCat Settings'>
      <CurrentUserSettings />
      <ActiveBoardSettings />
    </BasicPage>
  )
}

function CurrentUserSettings() {
  const dispatch = useDispatch()
  const currentUser = useSelector(s => s.auth.currentUser)

  return (
    <p>
      You're currently signed in as {currentUser.email}. <span className='link' onClick={() => dispatch(signOut())}>Sign out</span>
    </p>
  )
}

function ActiveBoardSettings() {
  const dispatch = useDispatch()
  const currentUser = useSelector(s => s.auth.currentUser)
  const activeBoard = useSelector(s => s.boards[currentUser.lastActiveBoardId])
  const history = useHistory()

  function invalidateBoard() {
    dispatch({type: 'boards/invalidate', meta: { boardId: activeBoard.data.id }})
    history.push(`/boards/${activeBoard.data.id}`)
  }

  if (activeBoard && activeBoard.data) {
    return (
      <p>
        Your active board is <Link to={`/boards/${activeBoard.data.id}`}>{activeBoard.data.name}</Link>.<br />
        {
          activeBoard.lastUpdated ?
            <Fragment>
              Data is loaded from {formatTimestamp(activeBoard.lastUpdated)}. <span className='link' onClick={invalidateBoard}>Refresh data</span><br />
            </Fragment> :
            null
        }
        <Link to='/home'>Change board</Link>
      </p>
    )
  } else {
    return 'Loading...'
  }
}
