import { REDUX_IDB_KEY, REDUX_IDB_VERSION } from './constants'
import { each, debounce, omit } from 'lodash'
import { set, get, del } from 'idb-keyval'
import {
  excludePersistence as uiExcludePersistence,
  initialState as uiInitialState
} from './reducers/ui'
import {
  excludePersistence as authExcludePersistence,
  initialState as authInitialState
} from './reducers/auth'
import { initialState as bluetoothInitialState } from './reducers/bluetooth'
import { initialState as boardListInitialState } from './reducers/boardList'

export async function getPersistedState() {
  if (window.PUPPETEER) return;
  let state = await get(REDUX_IDB_KEY);

  if (state && state.version === REDUX_IDB_VERSION) {
    let newStateData = state.data

    // Merge some defaults back into the state
    each(uiExcludePersistence, (x) => {
      newStateData.ui[x] = uiInitialState[x]
    })

    each(authExcludePersistence, (x) => {
      newStateData.auth[x] = authInitialState[x]
    })

    newStateData.bluetooth = bluetoothInitialState
    newStateData.boardList = boardListInitialState

    return newStateData;
  }
}

export const persistState = debounce((state) => {
  set(REDUX_IDB_KEY, {
    data: removeUnpersistableState(state),
    version: REDUX_IDB_VERSION
  })
}, 100)

export async function clearPersistedState() {
  return del(REDUX_IDB_KEY)
}

// Remove state slices that *should not* be persisted in idb
function removeUnpersistableState(state) {
  return {
    ...state,
    auth: omit(state.auth, authExcludePersistence),
    ui: omit(state.ui, uiExcludePersistence),
    bluetooth: null,
    boardList: null
  }
}
