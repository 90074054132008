import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBoardList } from '../reducers/boardList'
import BasicPage from '../components/BasicPage'
import { map, uniqBy, reject } from 'lodash'
import { sortAlphabetically } from '../utilities'

export default function JoinBoard() {
  const dispatch = useDispatch()
  const boardList = useSelector(s => s.boardList)
  const boards = useSelector(s => s.boards)
  const currentUser = useSelector(s => s.auth.currentUser)

  useEffect(() => {
    if (!boardList.data) {
      dispatch(fetchBoardList())
    }
  }, [boardList.data, dispatch])

  if (boardList.error) {
    return (
      <BasicPage title='Error loading boards'>
        <p>{boardList.error.code}</p>
      </BasicPage>
    )
  }

  if (!boardList.data) {
    return <BasicPage title='Loading boards…' />
  }

  // User can join public boards *or* boards that they already have loaded.
  const joinableBoards = sortAlphabetically(
    uniqBy(
      [
        ...reject(map(boards, 'data'), (b) => !b),
        ...boardList.data
      ],
      'id'
    ),
    'name'
  )

  return (
    <BasicPage title='Join an existing board'>
      {
        joinableBoards.length > 0 ?
          (
            <ul className='list-reset pb2'>
              {joinableBoards.map((board) =>
                <li key={board.id}>
                  <Link to={`/boards/${board.id}`}>{board.name}</Link>
                  {board.id === currentUser.lastActiveBoardId ?
                    <span className='tag ml1'>Active</span> :
                    null}
                </li>
              )}
            </ul>
          ) :
          <p>No boards found.</p>
      }

      <Link to='/home' className='btn btn-secondary'>Back home</Link>
    </BasicPage>
  )
}
