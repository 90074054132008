import React from 'react'
import { Switch, Route } from 'react-router-dom'

import {
  Start,
  SelfServe,
  Concierge
} from '../pages/precreation'

export default function PreCreationRoutes({board}) {
  return (
    <Switch>
      <Route path="/pre-creation/self-serve"><SelfServe /></Route>
      <Route path="/pre-creation/concierge"><Concierge /></Route>
      <Route path="/pre-creation"><Start /></Route>
    </Switch>
  )
}
