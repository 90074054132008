import React, { Fragment, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import StarRating from '../../components/StarRating'
import { createTick } from '../../reducers/ticks'
import { useDispatch } from 'react-redux'

export default function Tick({board, problem}) {
  const dispatch = useDispatch()
  const [myRating, setMyRating] = useState(null)
  const history = useHistory()

  function saveAndRedirect() {
    dispatch(createTick(board.data.id, problem.id, myRating))
    history.push(`/boards/${board.data.id}`)
  }

  return (
    <Fragment>
      <p className='h3 gray'>To tick this problem, give it a rating.</p>

      <label className='label'>My rating</label>
      <div>
        <StarRating
          value={myRating}
          onChange={(val) => setMyRating(val)}
        />
      </div>

      <div className='pt2'>
        <Link
          to={`/boards/${board.data.id}`}
          className='btn btn-secondary'
        >
          Back
        </Link>
        &nbsp;
        <button
          disabled={!myRating}
          onClick={saveAndRedirect}
          className='btn btn-primary'
        >
          Save tick
        </button>
      </div>
    </Fragment>
  )
}
