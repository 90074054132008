import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BasicPage from '../components/BasicPage'
import { signOut } from '../reducers/auth'

export default function Home() {
  const dispatch = useDispatch()
  const currentUser = useSelector(s => s.auth.currentUser)

  return (
    <BasicPage title={`Welcome to BoardCat, ${currentUser.username}.`}>
      <div className='center max-width-3 mx-auto'>
        <div className='pb1'>
          <Link to='/pre-creation' className='btn btn-primary'>Create a new board</Link>
          <div className='lg-inline'>
            &nbsp;
            or
            &nbsp;
          </div>
          <Link to='/join-board' className='btn btn-primary'>Join an existing board</Link>
        </div>
        <p className='my0 h6'>Not {currentUser.username}? <span className='link' onClick={() => dispatch(signOut())}>Sign out</span></p>
      </div>
    </BasicPage>
  )
}
