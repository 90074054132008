function postMessage(obj) {
  window.ReactNativeWebView.postMessage(JSON.stringify(obj))
}

const reactNative = {
  connect: ({onConnecting, onConnected, onErrorConnecting, onDisconnected}) => {
    window.ReactNativeBTCallbacks = { onConnecting, onConnected, onErrorConnecting, onDisconnected };
    postMessage({type: 'connect'})
  },
  disconnect: () => {
    postMessage({type: 'disconnect'})
  },
  write: (str) => {
    postMessage({type: 'write', payload: str})
  },
  clear: () => {
    reactNative.write('')
  }
}

export default reactNative
