import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Board } from '../../components/Board'
import SetupHeader from '../../components/SetupHeader'
import RangeAndNumberInput from '../../components/RangeAndNumberInput'
import {
  boardSettingsToTNutLocations,
  boardUnitsToPx,
  pxToBoardUnits,
  useAutofocusInput
} from '../../utilities'
import { useHistory } from 'react-router-dom'
import { FlexContainer, LeftColumn, RightColumn } from '../../components/layout'
import { size } from 'lodash'

export default function Start({board}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const nameInput = useAutofocusInput()

  function nextStep() {
    dispatch({
      type: 'boards/setInitialTNuts',
      meta: {
        boardId: board.data.id
      },
      payload: boardSettingsToTNutLocations(board.data.dimensions)
    })

    history.push(`/boards/${board.data.id}/setup/tnuts`)
  }

  return (
    <div>
      <SetupHeader
        board={board}
        showBoardName={false}
        active='start'
      />

      <div className='px2 lg-px3'>
        <p className='max-width-3'>
          The first step is to define your board layout. Think of this step as
          “installing the t-nuts” on your board. Most users will have a grid
          layout, so this page will attempt to set an evenly-spaced grid
          for you based on the dimensions you enter. (You can change it later.)
        </p>

        <p className='max-width-3 h6'>
          <em>
            Tip: don't worry about your measurements being exact in BoardCat.
            We're not trying to create a super-accurate scale model here. All we
            need to do is make the UI look similar enough to your board so that you
            can look at a problem on BoardCat and easily see which holds on your
            board that it maps to.
          </em>
        </p>

        <FlexContainer>
          <LeftColumn>
            <label className='label' htmlFor='name'>
              Board name
            </label>

            <input
              ref={nameInput}
              className='input'
              id='name'
              type='text'
              value={board.data.name}
              onChange={(e) => {
                dispatch({
                  type: 'boards/setProp',
                  meta: {
                    boardId: board.data.id,
                    prop: 'name'
                  },
                  payload: e.target.value
                })
              }}
            />

            {
              size(board.data.tnuts) === 0 ?
              <Fragment>
                <label className='label' htmlFor='units'>
                  Board units
                </label>

                <select
                  id='units'
                  className='select'
                  value={board.data.dimensions.units}
                  onChange={(e) => {
                    // update width/height for new units
                    dispatch({
                      type: 'boards/setDimensionsProp',
                      meta: {
                        boardId: board.data.id,
                        prop: 'width'
                      },
                      payload: pxToBoardUnits(
                        boardUnitsToPx(board.data.dimensions.width, board.data.dimensions.units),
                        e.target.value
                      )
                    })

                    dispatch({
                      type: 'boards/setDimensionsProp',
                      meta: {
                        boardId: board.data.id,
                        prop: 'height'
                      },
                      payload: pxToBoardUnits(
                        boardUnitsToPx(board.data.dimensions.height, board.data.dimensions.units),
                        e.target.value
                      )
                    })

                    dispatch({
                      type: 'boards/setDimensionsProp',
                      meta: {
                        boardId: board.data.id,
                        prop: 'units'
                      },
                      payload: e.target.value
                    })
                  }}
                >
                  <option value='mm'>mm</option>
                  <option value='cm'>cm</option>
                  <option value='in'>in</option>
                </select>

                <label className='label' htmlFor='width'>
                  Board width
                </label>

                <RangeAndNumberInput
                  id='width'
                  min={pxToBoardUnits(100, board.data.dimensions.units)}
                  max={pxToBoardUnits(5000, board.data.dimensions.units)}
                  value={board.data.dimensions.width}
                  onChange={(e) => {
                    dispatch({
                      type: 'boards/setDimensionsProp',
                      meta: {
                        boardId: board.data.id,
                        prop: 'width'
                      },
                      payload: e.target.value
                    })
                  }}
                  units={board.data.dimensions.units}
                />

                <label className='label' htmlFor='height'>
                  Board height
                </label>

                <RangeAndNumberInput
                  id='height'
                  min={pxToBoardUnits(100, board.data.dimensions.units)}
                  max={pxToBoardUnits(5000, board.data.dimensions.units)}
                  value={board.data.dimensions.height}
                  onChange={(e) => {
                    dispatch({
                      type: 'boards/setDimensionsProp',
                      meta: {
                        boardId: board.data.id,
                        prop: 'height'
                      },
                      payload: e.target.value
                    })
                  }}
                  units={board.data.dimensions.units}
                />

                <div className='flex'>
                  <div className='flex-auto pr1'>
                    <label className='label' htmlFor='numColumns'>
                      # columns
                    </label>

                    <input
                      id='numColumns'
                      className='input'
                      type='number'
                      min='1'
                      max='100'
                      value={board.data.dimensions.numColumns}
                      onChange={(e) => {
                        dispatch({
                          type: 'boards/setDimensionsProp',
                          meta: {
                            boardId: board.data.id,
                            prop: 'numColumns'
                          },
                          payload: e.target.value
                        })
                      }}
                    />
                  </div>
                  <div className='flex-auto pl1'>
                    <label className='label' htmlFor='numRows'>
                      # rows:
                    </label>

                    <input
                      id='numRows'
                      className='input'
                      type='number'
                      min='1'
                      max='100'
                      value={board.data.dimensions.numRows}
                      onChange={(e) => {
                        dispatch({
                          type: 'boards/setDimensionsProp',
                          meta: {
                            boardId: board.data.id,
                            prop: 'numRows'
                          },
                          payload: e.target.value
                        })
                      }}
                    />
                  </div>
                </div>
              </Fragment> : null
            }

            <div className='pt2'>
              <button disabled={board.data.name === ''} onClick={nextStep} className='btn btn-primary'>
                Continue to next step →
              </button>
            </div>
          </LeftColumn>

          <RightColumn>
            <Board
              board={board}
              tnutsNotCreatedYet={true}
              tnutStyle={'dark'}
            />
          </RightColumn>
        </FlexContainer>
      </div>
    </div>
  )
}
