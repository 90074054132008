import React from 'react'
import { Link } from 'react-router-dom'

export default function SetupHeader({
  board,
  showBoardName,
  active,
  description
}) {
  return (
    <div className='AppHeader'>
      <h1 className='h1 mb0'>
        <Link to={board.data.setupComplete ? `/boards/${board.data.id}` : '/home'} className='text-decoration-none'>BoardCat:</Link>
        &nbsp;
        {
          showBoardName ?
          <span>Configuring <em>{board.data.name}</em></span> :
          'Creating new board'}
      </h1>
      <ul className='list-reset my0'>
        <li className='inline pr1'>
          <Link
            to={`/boards/${board.data.id}/setup`}
            className={active === 'start' ? '' : 'text-decoration-none'}
          >Name board</Link>
          &nbsp;&nbsp;&rarr;
        </li>
        <li className='inline pr1'>
          <Link
            to={`/boards/${board.data.id}/setup/tnuts`}
            className={active === 'tnuts' ? '' : 'text-decoration-none'}
          >Define t-nut locations</Link>
          &nbsp;&nbsp;&rarr;
        </li>
        {
          board.data.ledEnabled ?
            (
              <li className='inline pr1'>
                <Link
                  to={`/boards/${board.data.id}/setup/leds`}
                  className={active === 'leds' ? '' : 'text-decoration-none'}
                >Setup LEDs</Link>
                &nbsp;&nbsp;&rarr;
              </li>
            ) : null
        }
        <li className='inline pr1'>
          <Link
            to={`/boards/${board.data.id}/setup/holds`}
            className={active === 'holds' ? '' : 'text-decoration-none'}
          >Upload hold images</Link>
          &nbsp;&nbsp;&rarr;
        </li>
        <li className='inline pr1'>
          <Link
            to={`/boards/${board.data.id}/setup/set`}
            className={active === 'set' ? '' : 'text-decoration-none'}
          >Set board</Link>
          &nbsp;&nbsp;&rarr;
        </li>
        <li className='inline'>
          <Link
            to={`/boards/${board.data.id}/setup/finish`}
            className={active === 'finish' ? '' : 'text-decoration-none'}
          >Finish up</Link>
        </li>
      </ul>
    </div>
  )
}

SetupHeader.defaultProps = {
  showBoardName: true
}
