import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

export function startBugsnag({store}) {
  Bugsnag.start({
    apiKey: '64cfe35517fc92d4629dbbd4eb1d83ef',
    appVersion: process.env.REACT_APP_GIT_SHA || 'development',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production', 'staging'],
    onError: function (event) {
      let user = store.getState().auth.currentUser

      if (user) {
        event.setUser(user.uid, user.email, user.username)
      }
    }
  })

  return {
    ErrorBoundary: Bugsnag.getPlugin('react').createErrorBoundary(React)
  }
}
