import React, { Fragment, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Board } from '../../components/Board'
import { FlexContainer, LeftColumn, RightColumn } from '../../components/layout'
import { useDispatch } from 'react-redux'
import SetupHeader from '../../components/SetupHeader'
import RangeAndNumberInput from '../../components/RangeAndNumberInput'
import { intersection, map, values, filter, reject, includes } from 'lodash'
import { sortAlphabetically, displayGrade, confirmAction, pxToBoardUnits } from '../../utilities'
import HoldSelect from '../../components/HoldSelect'
import { createTNut } from '../../reducers/boards'
import { TNUT_CIRCLE_SIZE } from '../../constants'

export default function SetHolds({board}) {
  const boardWrapper = useRef()
  const dispatch = useDispatch()
  const [editingIds, setEditingIds] = useState([])
  const editingTNuts = filter(board.data.tnuts, (i) => includes(editingIds, i.id))
  const history = useHistory()

  function nextStep() {
    history.push(`/boards/${board.data.id}/setup/finish`)
  }

  function editAllBetween(currentlyEditing, newEditing) {
    const xVals = [
      ...map(currentlyEditing, 'x'),
      newEditing.x
    ]

    const yVals = [
      ...map(currentlyEditing, 'y'),
      newEditing.y
    ]

    const xRange = [Math.min(...xVals), Math.max(...xVals)]
    const yRange = [Math.min(...yVals), Math.max(...yVals)]

    const newEditingTNuts = filter(board.data.tnuts, (i) => {
      return (
        xRange[0] <= i.x &&
        i.x <= xRange[1] &&
        yRange[0] <= i.y &&
        i.y <= yRange[1]
      )
    })

    setEditingIds(map(newEditingTNuts, 'id'))
  }

  function setCursor(val) {
    boardWrapper.current.style.cursor = val
  }

  return (
    <div>
      <SetupHeader
        board={board}
        active='set'
      />

      <div className='px2 lg-px3'>
        <p className='max-width-3'>
          Finally! It's time to set the locations of your holds on your board.
          We recommend doing this while looking at your board so that you can
          visually check your work.
        </p>

        <FlexContainer>
          <LeftColumn>
            <LeftColumnInner board={board} editingTNuts={editingTNuts} setEditingIds={setEditingIds} />

            <div className='pt2'>
              <button onClick={nextStep} className='btn btn-primary'>
                Done with holds →
              </button>
            </div>
          </LeftColumn>
          <RightColumn>
            <div ref={boardWrapper}>
              <Board
                board={board}
                editingTNutIds={editingIds}
                setCursor={setCursor}
                onTNutClick={(tnut, e) => {
                  if (editingTNuts.length > 0 && e.evt.shiftKey) {
                    editAllBetween(editingTNuts, tnut)
                  } else if (e.evt.metaKey) {
                    setEditingIds([
                      ...editingIds,
                      tnut.id
                    ])
                  } else {
                    setEditingIds([tnut.id])
                    if (tnut.screwOn) setCursor('move');
                  }
                }}
                onScrewOnMove={(x, y) => {
                  dispatch({
                    type: 'tnuts/setLocation',
                    meta: {
                      boardId: board.data.id,
                      tnutId: editingTNuts[0].id
                    },
                    payload: [x, y]
                  })
                }}
              />
            </div>
          </RightColumn>
        </FlexContainer>
      </div>
    </div>
  )
}

function LeftColumnInner({board, editingTNuts, setEditingIds}) {
  const [showAddScrewOnForm, setShowAddScrewOnForm] = useState(false)

  const images = board.subcollections.holdImages.data

  const holdOptions = map(
    sortAlphabetically(values(board.data.holds), 'name'),
    (hold) => {
      return {
        value: hold.id,
        label: hold.name,
        image: images[hold.imageId] && images[hold.imageId].base64data
      }
    }
  )

  function closeEditForm() {
    setShowAddScrewOnForm(false)
    setEditingIds([])
  }

  if (editingTNuts.length === 0) {
    if (showAddScrewOnForm) {
      return (
        <ScrewOnForm
          board={board}
          closeEditForm={closeEditForm}
          setEditingIds={setEditingIds}
          holdOptions={holdOptions}
        />
      )
    } else {
      return (
        <p className='h3 gray'>
          Choose a location to add a hold
          or <span className='link' onClick={() => setShowAddScrewOnForm(true)}>add a screw-on</span>
          .
        </p>
      )
    }
  }

  if (editingTNuts.length === 1) {
    return (
      <HoldSelectionForm
        board={board}
        holdOptions={holdOptions}
        problems={board.subcollections.problems}
        tnut={editingTNuts[0]}
        closeEditForm={closeEditForm}
      />
    )
  }

  if (editingTNuts.length > 1) {
    return (
      <MultipleHoldSelectionForm
        board={board}
        problems={board.subcollections.problems}
        tnuts={editingTNuts}
        closeEditForm={closeEditForm}
        setEditingIds={setEditingIds}
      />
    )
  }

  return null
}

function ScrewOnForm({board, holdOptions, closeEditForm, setEditingIds}) {
  const dispatch = useDispatch()

  function addScrewOn(holdId) {
    let [newTNutId, thunk] = createTNut(board.data.id, {
      holdId,
      screwOn: true,
      x: pxToBoardUnits(TNUT_CIRCLE_SIZE / 2, board.data.dimensions.units),
      y: pxToBoardUnits(TNUT_CIRCLE_SIZE / 2, board.data.dimensions.units)
    })
    dispatch(thunk)
    closeEditForm()
    setEditingIds([newTNutId])
  }

  return (
    <div>
      <HoldSelect
        tnutId={'screwon'}
        options={holdOptions}
        onChange={(selectedOption) => addScrewOn(selectedOption.value)}
      />

      <button className='btn btn-secondary' onClick={closeEditForm}>Cancel</button>
    </div>
  )
}

function HoldSelectionForm({board, holdOptions, problems, tnut, closeEditForm}) {
  const dispatch = useDispatch()

  const usedInProblems = filter(problems.data, (problem) => {
    return problem.added && includes(problem.holds, tnut.id)
  })

  function setOrientation(val) {
    dispatch({
      type: 'tnuts/setProp',
      meta: {
        boardId: board.data.id,
        tnutId: tnut.id,
        prop: 'orientation'
      },
      payload: val
    })
  }

  function removeScrewOn() {
    if (!confirmAction()) return;

    closeEditForm()

    dispatch({
      type: 'tnuts/remove',
      meta: {
        boardId: board.data.id,
        tnutId: tnut.id
      }
    })
  }

  return (
    <div>
      {tnut.screwOn ?
        <div
          className='inline-block rounded bg-gray mb1'
          style={{paddingLeft: '0.25rem', paddingRight: '0.25rem'}}
        >
          Screw-on
        </div> : null
      }

      {
        tnut.screwOn ?
          <div className='mb1'>
            <span className='link' onClick={removeScrewOn}>Remove screw-on</span>
          </div> :
          <Fragment>
            <label className='label' htmlFor='holdId'>Hold</label>

            <HoldSelect
              tnutId={tnut.id}
              options={holdOptions}
              value={tnut.holdId}
              onChange={(selectedOption) => {
                dispatch({
                  type: 'tnuts/setProp',
                  meta: {
                    boardId: board.data.id,
                    tnutId: tnut.id,
                    prop: 'holdId'
                  },
                  payload: selectedOption ? selectedOption.value : ''
                })
              }}
            />
          </Fragment>
      }

      <label className='label' htmlFor='orientation'>Orientation</label>
      <RangeAndNumberInput
        id='orientation'
        min={0}
        max={360}
        value={tnut.orientation}
        units={'°'}
        onChange={(e) => setOrientation(e.target.value)}
      />

      <ul className='list-reset mt0'>
        <li>
          <button onClick={() => setOrientation(0)}>N</button>
          <button onClick={() => setOrientation(45)}>NE</button>
          <button onClick={() => setOrientation(90)}>E</button>
          <button onClick={() => setOrientation(135)}>SE</button>
          <button onClick={() => setOrientation(180)}>S</button>
          <button onClick={() => setOrientation(225)}>SW</button>
          <button onClick={() => setOrientation(270)}>W</button>
          <button onClick={() => setOrientation(315)}>NW</button>
        </li>
      </ul>

      <label className='label'>Used in problems</label>
      {
        usedInProblems.length === 0 ?
          (
            <p className='dark-gray'>This hold is not used in any problems.</p>
          ) :
          (
            <ul>
              {
                map(usedInProblems, (problem) => {
                  return (
                    <li key={problem.id}>
                      <Link to={`/boards/${board.data.id}/problems/${problem.id}`}>{problem.name} {displayGrade(problem.grade)}</Link>
                    </li>
                  )
                })
              }
            </ul>
          )
      }

      <button className='btn btn-primary' onClick={closeEditForm}>Save</button>
    </div>
  )
}

function MultipleHoldSelectionForm({board, problems, tnuts, closeEditForm, setEditingIds}) {
  const dispatch = useDispatch()
  const tnutIds = map(tnuts, 'id')

  const usedInProblems = filter(problems.data, (problem) => {
    return problem.added && intersection(problem.holds, tnutIds).length > 0
  })

  function clearHolds() {
    if (confirmAction()) {
      let screwOnIds = map(filter(tnuts, { screwOn: true }), 'id')
      let nonScrewOnIds = map(reject(tnuts, { screwOn: true }), 'id')

      if (screwOnIds.length) {
        dispatch({
          type: 'tnuts/removeMany',
          meta: { boardId: board.data.id, tnutIds: screwOnIds }
        })
      }

      if (nonScrewOnIds.length) {
        dispatch({
          type: 'tnuts/setPropMany',
          meta: {
            boardId: board.data.id,
            tnutIds: nonScrewOnIds,
            prop: 'holdId'
          },
          payload: ''
        })
      }

      setEditingIds(nonScrewOnIds)
    }
  }

  return (
    <div>
      <label className='label' htmlFor='holdId'>Hold</label>
      <div className='pb2'>
        <span className='link' onClick={clearHolds}>Clear {tnutIds.length} holds</span>
      </div>

      <label className='label'>Used in problems</label>
      {
        usedInProblems.length === 0 ?
          (
            <p className='dark-gray'>These holds are not used in any problems.</p>
          ) :
          (
            <ul>
              {
                map(usedInProblems, (problem) => {
                  return (
                    <li key={problem.id}>
                      <Link to={`/boards/${board.data.id}/problems/${problem.id}`}>{problem.name} {displayGrade(problem.grade)}</Link>
                    </li>
                  )
                })
              }
            </ul>
          )
      }

      <button className='btn btn-primary' onClick={closeEditForm}>Save</button>
    </div>
  )
}
