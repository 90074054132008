import {
  SERVICE_ID,
  CHARACTERISTIC_ID
} from './constants'

const state = {
  device: null,
  characteristic: null
}

const web = {
  connect: ({onConnecting, onConnected, onErrorConnecting, onDisconnected}) => {
    navigator.bluetooth.requestDevice({
      filters: [{services: [SERVICE_ID]}]
    }).then((device) => {
      onConnecting()
      state.device = device
      state.device.addEventListener('gattserverdisconnected', onDisconnected)
      return device.gatt.connect()
    }).then((server) => {
      return server.getPrimaryService(SERVICE_ID)
    }).then((service) => {
      return service.getCharacteristic(CHARACTERISTIC_ID)
    }).then((characteristic) => {
      state.characteristic = characteristic
      onConnected()
    }).catch((e) => {
      onErrorConnecting()
    })
  },
  disconnect: () => {
    if (!state.device) return;
    state.device.gatt.disconnect()
    state.device = null;
    state.characteristic = null;
  },
  write: (str) => {
    if (!state.characteristic) return;
    state.characteristic.writeValue(new TextEncoder("utf-8").encode(str))
  },
  clear: () => {
    web.write('')
  }
}

export default web
