import React from 'react'
import { Switch, Route } from 'react-router-dom'

import {
  Start,
  TNuts,
  Leds,
  Holds,
  SetHolds,
  Finish
} from '../pages/setup'

export default function SetupRoutes({board}) {
  return (
    <Switch>
      <Route path="/boards/:id/setup/tnuts"><TNuts board={board} /></Route>
      <Route path="/boards/:id/setup/leds"><Leds board={board} /></Route>
      <Route path="/boards/:id/setup/holds"><Holds board={board} /></Route>
      <Route path="/boards/:id/setup/set"><SetHolds board={board} /></Route>
      <Route path="/boards/:id/setup/finish"><Finish board={board} /></Route>
      <Route path="/boards/:id/setup"><Start board={board} /></Route>
    </Switch>
  )
}
