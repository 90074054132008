import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'

const configs = []
let environment

if (window.location.hostname === 'app.boardc.at') {
  environment = 'production'
} else if (window.location.hostname === 'boardcat-staging-f2a0f.web.app') {
  environment = 'staging'
} else if (process.env.REACT_APP_TEST_ENV === 'true') {
  environment = 'test'
} else {
  environment = 'development'
}

configs.production = {
  apiKey: 'AIzaSyDlHCfZwnbLFuOuLPgzioypMwX9aWHm5PM',
  authDomain: 'boardcat-8b289.firebaseapp.com',
  projectId: 'boardcat-8b289',
  appId: '1:530379307160:web:8c5d0153f8f0746153b8ed',
  measurementId: "G-61BH6VR0WK"
}

configs.staging = {
  apiKey: "AIzaSyBB1v5VNHbnD5E_ud-nc03ruUDtzZrYI4k",
  authDomain: "boardcat-staging-f2a0f.firebaseapp.com",
  projectId: "boardcat-staging-f2a0f",
  appId: "1:919055645372:web:2623fe49108126119e2eb9"
}

configs.test = {
  apiKey: "AIzaSyCYbRRgu1Qr1N9SyLJEwqiwZ5J0P-MJa7Q",
  authDomain: "boardcat-test.firebaseapp.com",
  projectId: "boardcat-test",
  appId: "1:713602509991:web:7ffcf68b649be34b6eff82"
}

configs.development = {
  apiKey: "AIzaSyA7BzOsSi7L0GVPOL5hNuVUTcbGuhV1UT4",
  authDomain: "boardcat-development.firebaseapp.com",
  projectId: "boardcat-development",
  appId: "1:175628990494:web:56101c5d7e643d06b9560f",
  measurementId: "G-Q39JT0S1TE"
}

firebase.initializeApp(configs[environment])

const fakeAnalytics = {
  logEvent: () => {}
}

export const analytics = configs[environment].measurementId && (typeof(jest) === 'undefined') ?
                          firebase.analytics() :
                          fakeAnalytics

export const db = firebase.firestore()
export default firebase
