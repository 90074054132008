import React, { useState } from 'react'
import { range, map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export default function StarRating({value, onChange}) {
  let [hoveringVal, setHoveringVal] = useState(null)
  const displayValue = hoveringVal || value

  return (
    <div>
      {
        map(range(4), (i) => {
          let starVal = i + 1
          let icon

          if (displayValue >= starVal) {
            icon = faStar
          } else {
            icon = faStarEmpty
          }

          return (
            <FontAwesomeIcon
              style={{cursor: 'pointer'}}
              icon={icon}
              color={'#ffcd4e'}
              key={i}
              onMouseEnter={() => setHoveringVal(starVal)}
              onMouseLeave={() => setHoveringVal(null)}
              onClick={() => onChange(starVal)}
            />
          )
        })
      }
    </div>
  )
}
