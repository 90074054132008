import React from 'react'

export default function RangeAndNumberInput({
  id,
  step,
  min,
  max,
  value,
  onChange,
  units,
  className
}) {
  return (
    <div className={`${className || ''} flex py1`}>
      <input
        type='range'
        className='col-8 mr1'
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
      <input
        id={id}
        type='number'
        className='col-3 mr1'
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />

      <span className='regular'>{units}</span>
    </div>
  )
}
