export const REDUX_IDB_KEY = 'bcstore-versioned'

// bump this when we need to force a refresh
export const REDUX_IDB_VERSION = '0.1'

export const TNUT_SIZE = 20
export const TYPICAL_HOLD_SIZE = 127
export const TNUT_CIRCLE_SIZE = TNUT_SIZE + TYPICAL_HOLD_SIZE + 100

export const DEFAULT_BOARD_WIDTH = 243.84 // 8'
export const DEFAULT_BOARD_HEIGHT = 365.76 // 12'
export const DEFAULT_BOARD_UNITS = 'cm' // 10.3'
export const DEFAULT_NUM_COLUMNS = 11
export const DEFAULT_NUM_ROWS = 18

// manually keep synced with firestore.rules
export const SUPERADMIN_UIDS = [
  'CiYjTAybmKgJfO8olemccMtA9lS2', // adam prod
  'iTv17nbYvIccFi0f2yE71kn9BSF3' // adam dev
]
