import React, { useEffect } from 'react'
import LoadingScreen from '../components/LoadingScreen'
import firebase from '../firebase'
import { useSelector, useDispatch } from 'react-redux'
import { loadUserDataAndSetCurrentUser } from '../reducers/auth'

export default function FirebaseAuthStateWatcher(props) {
  const dispatch = useDispatch()
  const authStateReceived = useSelector(s => s.auth.authStateReceived)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        dispatch(loadUserDataAndSetCurrentUser(user))
      } else {
        dispatch({type: 'auth/clearCurrentUser'})
      }
    })
  }, [dispatch])

  // Currently you cannot use the app before it loads the auth state.
  // Eventually, we will store the auth state locally so that the app can be
  // used offline...
  return authStateReceived ?
          props.children :
          <LoadingScreen />
}
