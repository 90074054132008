import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Board } from '../../components/Board'
import { FlexContainer, LeftColumn, RightColumn } from '../../components/layout'
import SetupHeader from '../../components/SetupHeader'
import { filter, includes, isNumber } from 'lodash'
import { setLedsByIdx } from '../../bluetooth'

export default function Leds({board}) {
  const [editingIds, setEditingIds] = useState([])
  const history = useHistory()
  const editingTNuts = filter(board.data.tnuts, (i) => includes(editingIds, i.id))
  const editingTNutLedIdx = editingTNuts[0] ? editingTNuts[0].ledIdx : null

  // Update LEDs
  useEffect(() => {
    setLedsByIdx(isNumber(editingTNutLedIdx) ? {[editingTNutLedIdx]: 'r'} : null)
  }, [ editingTNutLedIdx])

  function nextStep() {
    history.push(`/boards/${board.data.id}/setup/holds`)
  }

  function closeEditForm() {
    setEditingIds([])
  }

  return (
    <div>
      <SetupHeader
        board={board}
        active='leds'
      />

      <div className='px2 lg-px3'>
        <FlexContainer>
          <LeftColumn>
            {editingTNuts.length === 0 ? <BlankSlate /> : null}

            {editingTNuts.length === 1 ?
              <EditTNutForm
                board={board}
                tnut={editingTNuts[0]}
                closeEditForm={closeEditForm}
              /> : null
            }

            <div className='pt2'>
              <button onClick={nextStep} className='btn btn-primary'>
                Continue to next step →
              </button>
            </div>
          </LeftColumn>
          <RightColumn>
            <Board
              board={board}
              disableShowGrid={true}
              tnutStyle={'dark'}
              editingTNutIds={editingIds}
              onTNutClick={(tnut, e) => {
                setEditingIds([tnut.id])
              }}
            />
          </RightColumn>
        </FlexContainer>
      </div>
    </div>
  )
}

function EditTNutForm({board, tnut, closeEditForm}) {
  const dispatch = useDispatch()
  const input = useRef()
  const tnutId = tnut.id

  useEffect(() => {
    input.current && input.current.focus()
  }, [tnutId])

  return (
    <div className='pb2'>
      <div>
        <label className='label'>LED Index</label>
        <input
          ref={input}
          className='input'
          type='number'
          min='0'
          max='500'
          value={typeof tnut.ledIdx === 'undefined' ? '' : tnut.ledIdx}
          onChange={(e) => {
            dispatch({
              type: 'tnuts/setProp',
              meta: {
                boardId: board.data.id,
                tnutId: tnut.id,
                prop: 'ledIdx'
              },
              payload: e.target.value
            })
          }}
          />
      </div>

      <button className='btn btn-primary' onClick={() => { closeEditForm() }}>Done</button>
    </div>
  )
}

function BlankSlate() {
  return (
    <p className='h3 gray'>
      Select a t-nut to map its LED address.
    </p>
  )
}
