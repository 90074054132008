import React from 'react'
import { Link } from 'react-router-dom'
import BasicPage from '../../components/BasicPage'

export default function Start() {

  return (
    <BasicPage title='Create a new board'>
      <div className='center max-width-2 mx-auto justify'>
        <p>
          BoardCat requires a little extra setup, but we promise that the payoff
          is worth it. To start, you'll need high-quality images of all the holds
          on your climbing wall. You can usually find these from the hold
          manufacturer, but we can also help you take and crop photos of your holds.
        </p>

        <p>
          If you're thinking, "this sounds like a lot of work!", you're right. But
          there are ways to do it efficiently. Image editing software is pretty good at
          quickly segmenting images, and in future, BoardCat might be able to do this automatically.
          For now, you have two options:
        </p>

        <div className='pb1'>
          <Link to='/pre-creation/self-serve' className='btn btn-primary'>I will crop images myself</Link>
          <div className='lg-inline'>
            &nbsp;
            or
            &nbsp;
          </div>
          <Link to='/pre-creation/concierge' className='btn btn-primary'>I want you to do it for me</Link>
        </div>
      </div>
    </BasicPage>
  )
}
