import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import createConfiguredStore from './store'
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { startBugsnag } from './bugsnag'
import ErrorNotification from './components/ErrorNotification'

// Styles
import 'colors.css/css/colors.css'
import 'basscss/css/basscss-cp.css'
import 'basscss-forms/index.css'
import 'basscss-btn/css/btn.css'
import 'basscss-btn-primary/css/btn-primary.css'
import 'basscss-btn-outline/css/btn-outline.css'
import './index.css'

createConfiguredStore().then((store) => {
  const { ErrorBoundary } = startBugsnag({store})

  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorNotification}>
        <Router>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  )

  serviceWorker.register({
    onUpdate: (reg) => {
      store.dispatch({type: 'ui/serviceWorkerUpdated', payload: reg})
    }
  })
})
