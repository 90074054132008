import { produce } from 'immer'
import dbFns from '../dbFns'

export const initialState = {
  serviceWorker: {
    isUpdated: false,
    registration: null
  },
  activeProblemId: null,
  editingProblemHolds: false,
  editingProblemTnutId: null,
  boardUpdateQueued: false,
  problemUpdateQueued: false,
  savesInProgress: 0,
  batchHoldImageUpload: {
    isUploading: false,
    error: null
  },
  problemsList: {
    showFilters: false,
    isSelectingHold: false,
    sortBy: 'alpha', // options: 'newest', 'oldest', 'best', 'easiest', 'hardest',
    filters: {
      search: null,
      minGrade: null,
      maxGrade: null,
      setter: null,
      holdIds: []
    }
  }
}

export const excludePersistence = [
  'serviceWorker',
  'boardUpdateQueued',
  'problemUpdateQueued',
  'savesInProgress',
  'batchHoldImageUpload'
]

// Action creators:

export function batchUploadHoldImages(boardId, batchArr) {
  return (dispatch) => {
    dispatch({type: 'ui/batchHoldImageUploadBegin'})

    dbFns.setHoldImagesBatch(batchArr).then(() => {
      dispatch({type: 'ui/batchHoldImageUploadSuccess'})
      dbFns.getHoldImages(boardId, dispatch)
    }).catch((err) => {
      dispatch({type: 'ui/batchHoldImageUploadError', payload: err})
    })
  }
}

// Reducer:

export default function ui(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'ui/reset':
      return initialState

      case 'ui/setActiveProblemId':
      draft.activeProblemId = action.payload
      return draft

      case 'ui/setEditingProblemHolds':
      draft.editingProblemHolds = action.payload
      return draft

      case 'ui/setEditingProblemTnutId':
      draft.editingProblemTnutId = action.payload
      return draft

      case 'ui/setBoardUpdateQueued':
      draft.boardUpdateQueued = action.payload
      return draft

      case 'ui/setProblemUpdateQueued':
      draft.problemUpdateQueued = action.payload
      return draft

      case 'ui/incrementSavesInProgress':
      draft.savesInProgress++
      return draft

      case 'ui/decrementSavesInProgress':
      draft.savesInProgress--
      return draft

      case 'ui/batchHoldImageUploadBegin':
      draft.batchHoldImageUpload.isUploading = true
      return draft

      case 'ui/batchHoldImageUploadSuccess':
      draft.batchHoldImageUpload.isUploading = false
      return draft

      case 'ui/batchHoldImageUploadFailure':
      draft.batchHoldImageUpload.isUploading = false
      draft.batchHoldImageUpload.error = action.payload
      return draft

      case 'ui/serviceWorkerUpdated':
      draft.serviceWorker.isUpdated = true
      draft.serviceWorker.registration = action.payload
      return draft

      case 'problemsList/setSortBy':
      draft.problemsList.sortBy = action.payload
      return draft

      case 'problemsList/toggleShowFilters':
      draft.problemsList.showFilters = !state.problemsList.showFilters
      return draft

      case 'problemsList/clearFilters':
      draft.problemsList = initialState.problemsList
      return draft

      case 'problemsList/setFilter':
      if (['minGrade', 'maxGrade'].includes(action.meta.prop)) {
        action.payload = parseInt(action.payload, 10)
      }

      draft.problemsList.filters[action.meta.prop] = action.payload
      return draft

      case 'problemsList/toggleIsSelectingHold':
      draft.problemsList.isSelectingHold = !state.problemsList.isSelectingHold
      return draft

      default:
      return draft
    }
  })
}
