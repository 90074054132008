import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import BasicPage from '../../components/BasicPage'
import { createBoard } from '../../reducers/boards'

export default function Start() {
  const dispatch = useDispatch()
  let history = useHistory()

  function newBoard() {
    let [newBoardId, thunk] = createBoard()
    dispatch(thunk)
    history.push(`/boards/${newBoardId}/setup`)
  }

  return (
    <BasicPage title='Photo tips'>
      <div className='center max-width-2 mx-auto justify'>
        <p>
          Most people will already have their climbing board set with holds.
          If this is the case, you'll need to take a high-quality photo of the board.
          Try to hold your camera at the same angle as the board so that the holds
          appear flat against the wall. If you can use as much lighting as possible
          so that the holds contrast against the background, that will also be helpful.
        </p>

        <p>
          If your climbing wall is not currently set, or if you're thinking of stripping
          your wall to reset it, that's even better. If this is the case, we recommend laying
          your holds out on a flat-colored background and taking one (or more) photos
          with all of the holds in them. This way, it will be even easier to segment the
          holds from the background.
        </p>

        <div className='py1 center'>
          <button onClick={newBoard} className='btn btn-primary'>Start creating my board</button>
        </div>
      </div>
    </BasicPage>
  )
}
