import React from 'react'

export default function BasicPage({title, children}) {
  return (
    <div className='center px2 pb4 dark-gray' style={{height: '100vh', width: '100vw'}}>
      <div className='pt4'>
        <img src='/logo192.png' alt='BoardCat logo' width='96' height='96' />
        <h1 className='h1 dark-gray'>{title}</h1>

        {children}
      </div>
    </div>
  )
}
