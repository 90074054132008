import React from 'react'
import BasicPage from './BasicPage'
import { clearPersistedState } from '../statePersistence'

export default function ErrorNotification() {
  function clearData() {
    clearPersistedState().then(() => {
      window.location.reload();
    })
  }

  return (
    <BasicPage title="We're sorry, an error occurred.">
      <p>We've already been notified, but please let us know if this continues to occur.</p>
      <p>
        You can also try <span className='link' onClick={clearData}>clearing stored data and refreshing the page</span>.
      </p>
    </BasicPage>
  )
}
