import { produce } from 'immer'
import { generateId } from '../utilities'
import dbFns from '../dbFns'

const initialState = {
  isFetching: false,
  lastUpdated: null,
  error: null,
  data: null
}

// Actions:

export function fetchTicks(uid) {
  return function (dispatch) {
    dispatch({type: 'ticks/loadBegin'})

    dbFns.getTicksForUser(uid).then((ticks) => {
      dispatch({
        type: 'ticks/loadSuccess',
        meta: {
          ts: Date.now()
        },
        payload: ticks
      })
    }).catch((err) => {
      dispatch({
        type: 'ticks/loadFailure',
        payload: err
      })
    })
  }
}

export function createTick(boardId, problemId, rating) {
  return function (dispatch, getState) {
    let payload = {
      id: generateId(),
      boardId: boardId,
      problemId: problemId,
      userId: getState().auth.currentUser.uid,
      createdAt: Date.now(),
      rating: rating
    }

    dbFns.setTick(payload)

    dispatch({
      type: 'ticks/create',
      meta: { tickId: payload.id },
      payload: payload
    })
  }
}

export function deleteTick(tickId) {
  return function (dispatch) {
    dispatch({type: 'ticks/remove', meta: { tickId }})
    dbFns.deleteTick(tickId)
  }
}

// Reducer:

export default function ticks(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'ticks/create':
      draft.data[action.meta.tickId] = action.payload
      return draft

      case 'ticks/remove':
      delete draft.data[action.meta.tickId]
      return draft

      case 'ticks/loadBegin':
      draft.isFetching = true
      return draft

      case 'ticks/loadSuccess':
      draft.isFetching = false
      draft.lastUpdated = action.meta.ts
      draft.data = action.payload
      return draft

      case 'ticks/loadFailure':
      draft.isFetching = false
      draft.error = action.payload
      return draft

      default:
      return draft
    }
  })
}
