import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import firestoreController from './firestoreController'
import reducer from './reducers/index'
import { getPersistedState, persistState } from './statePersistence'

export default async function createConfiguredStore() {
  let persistedState = await getPersistedState()

  let store = createStore(reducer, persistedState || {}, composeWithDevTools(
    applyMiddleware(
      firestoreController,
      thunkMiddleware
    )
  ))

  store.subscribe(() => persistState(store.getState()))

  return store
}
