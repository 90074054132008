import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { canManageBoard } from '../permissions'
import { createProblem } from '../reducers/boards'
import { connectBluetooth } from '../reducers/bluetooth'
import { bluetoothSupported, disconnect } from '../bluetooth'

export default function BoardHeader ({board}) {
  const currentUser = useSelector(s => s.auth.currentUser)
  const dispatch = useDispatch()
  const history = useHistory()
  const isConnected = useSelector(s => s.bluetooth.isConnected)
  const isConnecting = useSelector(s => s.bluetooth.isConnecting)

  function newProblemClick() {
    let [newProblemId, thunk] = createProblem(board.data.id)
    dispatch(thunk)
    history.push(`/boards/${board.data.id}/problems/${newProblemId}/holds`)
  }

  return (
    <div className='AppHeader'>
      <h1 className='h1 mb0'>
        <Link to={`/boards/${board.data.id}`} className='text-decoration-none'>BoardCat<span className='xs-hide sm-hide md-hide'>:</span></Link>
        <span className='xs-hide sm-hide md-hide'>
          &nbsp;
          <em>{board.data.name}</em>
        </span>
      </h1>

      <ul className='list-reset my0'>
        <li className='inline pr2'><span className='link' onClick={newProblemClick}>New problem</span></li>

        {
          canManageBoard(currentUser, board) ?
            <li className='inline pr2'>
              <Link to={`/boards/${board.data.id}/setup/tnuts`}>Configure<span className='xs-hide sm-hide'> board</span></Link>
            </li> : null
        }

        {
          board.data.ledEnabled && bluetoothSupported ?
            (
              <li className='inline pr2'>
                {
                  isConnecting ?
                    'Connecting…' :
                    (
                      isConnected ?
                        <span className='link' onClick={() => disconnect()}>Disconnect Bluetooth</span> :
                        <span className='link' onClick={() => dispatch(connectBluetooth())}>Connect Bluetooth</span>
                    )
                }
              </li>
            ) : null
        }

        <li className='inline'><Link to='/settings'>Settings</Link></li>
      </ul>
    </div>
  )
}
