import React, { Fragment, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { map, range } from 'lodash'
import { useAutofocusInput } from '../../utilities'
import StarRating from '../../components/StarRating'
import { createTick } from '../../reducers/ticks'
import { analytics } from '../../firebase'

export default function Details({board, problem}) {
  const dispatch = useDispatch()
  const [tickProblem, setTickProblem] = useState(true)
  const [myRating, setMyRating] = useState(3)
  const history = useHistory()
  const nameInput = useAutofocusInput()

  function nextStep() {
    if (!problem.added) analytics.logEvent('problem_added');

    if (!problem.added && tickProblem) {
      dispatch(createTick(board.data.id, problem.id, myRating))
    }

    dispatch({
      type: 'problems/setProp',
      meta: {
        boardId: board.data.id,
        problemId: problem.id,
        prop: 'added'
      },
      payload: true
    })

    history.push(`/boards/${board.data.id}`)
  }

  return (
    <Fragment>
      <label className='label' htmlFor='name'>Name</label>
      <input
        id='name'
        className='input'
        type='text'
        value={problem.name}
        ref={nameInput}
        onChange={(e) => {
          dispatch({
            type: 'problems/setProp',
            meta: {
              boardId: board.data.id,
              problemId: problem.id,
              prop: 'name'
            },
            payload: e.target.value
          })
        }}
      />

      <label className='label' htmlFor='grade'>Grade</label>
      <select
        className='select'
        id='grade'
        value={problem.grade}
        onChange={(e) => {
          dispatch({
            type: 'problems/setProp',
            meta: {
              boardId: board.data.id,
              problemId: problem.id,
              prop: 'grade'
            },
            payload: e.target.value
          })
        }}
      >
        <option value='unknown'>Unknown</option>

        {
          map(range(0, 15), (num) =>
            <option value={num} key={num}>V{num}</option>
          )
        }
      </select>

      <label className='label' htmlFor='feetType'>Feet</label>
      <select
        className='select'
        id='feetType'
        value={problem.feetType}
        onChange={(e) => {
          dispatch({
            type: 'problems/setProp',
            meta: {
              boardId: board.data.id,
              problemId: problem.id,
              prop: 'feetType'
            },
            payload: e.target.value
          })
        }}
      >
        <option value='tracking'>Tracking</option>
        <option value='open'>Open feet</option>
      </select>

      {
        !problem.added ?
          <Fragment>
            <div className='mt2'>
              <label className='flex-auto'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    checked={tickProblem}
                    onChange={(e) => setTickProblem(e.target.checked)}
                    className='mr1'
                  />

                  <span>Tick this problem</span>
                </div>
              </label>

              {
                tickProblem ?
                  <div className='mt1'>
                    <label className='label'>My rating</label>
                    <div>
                      <StarRating
                        value={myRating}
                        onChange={(val) => setMyRating(val)}
                      />
                    </div>
                  </div> : null
              }
            </div>
          </Fragment> : null
      }

      <div className='pt2'>
        <Link
          to={`/boards/${board.data.id}/problems/${problem.id}/holds`}
          className='btn btn-secondary'
        >
          Back
        </Link>
        &nbsp;
        <button
          disabled={problem.name === ''}
          onClick={nextStep}
          className='btn btn-primary'
        >
          {
            problem.added ?
              'Save problem' :
              'Add problem →'
          }
        </button>
      </div>
    </Fragment>
  )
}
